import React, { useState, useEffect } from "react";
import showdown from "showdown";

const converter = new showdown.Converter();

function History({ filePath }) {
  const [content, setContent] = useState("");

  useEffect(() => {
    const fileContent = fetch(filePath)
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, [filePath]);

  const htmlContent = converter.makeHtml(content);

  return (
    <div
      className="markdown"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
}

export default History;

import researchVec from "../res/vectors/research-b.png";
import scienceVec from "../res/vectors/science-b.png";
import techVec from "../res/vectors/tech-b.png";

function About() {
  return (
    <section
      id="about"
      className="w-screen lg:mt-auto pt-6 pb-12 bg-sky-50 flex justify-center items-center text-black h-auto"
    >
      <div className="flex flex-col m-0 py-1  lg:flex-row w-screen lg:w-[1000px]">
        <div className="flex flex-row justify-center items-center gap-10 lg:gap-12 lg:flex-col w-screen lg:w-24 py-0  lg:pt-10">
          <img className="h-16 lg:h-24" src={researchVec} alt="" />
          <img className="h-16 lg:h-24" src={scienceVec} alt="" />
          <img className="h-16 lg:h-24" src={techVec} alt="" />
        </div>
        <div className=" mt-7 flex-1  pl-0 lg:pl-10">
          <div>
            <h1 className="text-3xl font-light text-center lg:text-left lg:text-5xl text-sky-900">
              ABOUT THE JOURNAL
            </h1>
          </div>
          <div className="font-light text-md text-center lg:text-left lg:font-light lg:text-xl px-4">
            <p className="mt-2">
              The Asian Journal of Research in Science and Technology (AJORIST)
              is a prestigious online research journal dedicated to promoting
              and advancing scientific knowledge and technological innovations
              across a wide range of disciplines. With a focus on the Asian
              region and its contributions to the global scientific community,
              AJORIST provides a platform for researchers, scientists,
              academicians, and professionals to publish their original research
              papers and engage in meaningful scientific discussions.
            </p>
            <p className="mt-2">
              AJORIST embraces the principles of open access, making all
              published articles freely available to readers worldwide. This
              inclusive approach promotes the widespread dissemination of
              knowledge, facilitating the exchange of ideas and inspiring
              further research and innovation.
            </p>
            <p className="mt-2">
              <b>
                Asian Journal of Research in Science and Technology (AJORIST)
                publishes semi-annually.
              </b>{" "}
              The journal publishes papers that have undergone a rigorous
              peer-review process and cover a wide range of topics in science
              and technology. By publishing twice a year, AJORIST provides a
              timely platform for researchers to share their latest findings,
              engage in scientific discussions, and contribute to the
              advancement of knowledge.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;

import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, updateDoc, doc } from "firebase/firestore";

function EditNews(props) {
  const activeNews = props.activeNews;
  const newsRef = collection(db, "news");
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [lead, setLead] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    setId(activeNews.id);
    setTitle(activeNews.title);
    setDate(activeNews.date);
    setLead(activeNews.lead);
    setBody(activeNews.body);
  }, []);

  const updateNews = async (id) => {
    const newsDoc = doc(db, "news", id);
    const newsInputs = {
      title: title,
      date: date,
      lead: lead,
      newsBody: body,
    };
    await updateDoc(newsDoc, newsInputs);
    alert("News Updated");
    props.refreshNews();
    props.closeForm();
  };

  return (
    <div
      className={` absolute w-screen h-screen m-0 p-0 border-0 top-0 left-0`}
    >
      <div className="fixed w-screen h-screen bg-slate-800 opacity-20"></div>
      <div className="fixed w-screen h-screen flex justify-center items-center">
        <div className="h-5/6 w-5/6 bg-white rounded-xl flex flex-col p-4 gap-4">
          <div className="flex-1 flex flex-col gap-4">
            <div className="flex flex-row items-center">
              <label className="w-20">News Title:</label>
              <input
                className="flex-1"
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-row items-center">
              <label className="w-20">Date:</label>
              <input
                className="flex-1"
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-row items-center">
              <label className="w-20">News Lead:</label>
              <input
                className="flex-1"
                type="text"
                value={lead}
                onChange={(e) => {
                  setLead(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-row items-center flex-1">
              <label className="w-20">Body:</label>
              <textarea
                className="resize-none flex-1 h-full"
                value={body}
                onChange={(e) => {
                  setBody(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-4">
            <div
              className="bg-sky-600 rounded w-32 h-8 flex justify-center items-center font-bold text-white"
              onClick={() => {
                updateNews(activeNews.id);
              }}
            >
              Update News
            </div>
            <div
              className="bg-rose-600 rounded w-20 h-8  flex justify-center items-center text-white font-bold res"
              onClick={props.closeForm}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditNews;

import Cite from "./res/cite.png";
import Case from "./res/logo.png";

const Colleges = [
  {
    id: 1,
    title: "CITE",
    name: "College of Information Technology and Engineering",
    logo: Cite,
    color: "c1",
    departments: [
      {
        idNum: 1,
        shortname: "BSCE",
        title: "Bachelor of Science in Civil Engineering",
        shortdesc:
          "The Bachelor of Science in Civil Engineering is intended to provide graduates with the skills and knowledge needed to design, build, and operate engineered systems such as roads, foundations, buildings, bridges, dams, airports, and water supply and wastewater treatment systems.",
        url: "bs-civil-engineering",
      },
      {
        idNum: 2,
        nameshortname: "BSEE",
        title: "Bachelor of Science in Electrical Engineering",
        shortdesc:
          "The Bachelor of Science in Electrical Engineering program concentrates on developing skills in electrical power generation, transmission and distribution, electrical wiring and equipment design, as well as lighting systems of residential, commercial and industrial establishments.",
        url: "bs-electrical-engineering",
      },
      {
        idNum: 3,
        shortname: "BSIT",
        title: "Bachelor of Science in Information Technology",
        shortdesc:
          "The Bachelor of Science in Information Technology is a degree program that equips students with the basic ability to conceptualize, design and implement software applications. It prepares students to be IT professionals who are able to perform installation, operation, development, maintenance, and administration of computer applications.",
        url: "bs-information-technology",
      },
    ],
  },
  {
    id: 2,
    title: "CASE",
    name: "College of Arts, Sciences and Education",
    logo: Case,
    color: "c2",
    departments: [
      {
        idNum: 4,
        shortname: "BEEDGENED",
        title: "Bachelor of Elementary Education Major in General Education",
        shortdesc:
          "The Bachelor of Elementary Education with a major in General Education is a program that prepares students to teach in elementary schools. It provides a well-balanced understanding of learning theories and concepts on both qualitative and quantitative levels.",
        url: "beed-general-education",
      },
      {
        idNum: 5,
        shortname: "BSEDMATH",
        title: "Bachelor of Secondary Education Major in Mathematics",
        shortdesc:
          "The Bachelor of Secondary Education with a major in Mathematics is a program that prepares  prepares Teacher  Education students in teaching the different branches of Mathematics such as Algebra, Geometry, Trigonometry, Calculus and Statistics to Secondary school students.",
        url: "bsed-mathematics",
      },
      {
        idNum: 6,
        shortname: "BSACC",
        title: "Bachelor of Science in Accountancy",
        shortdesc:
          "The Bachelor of Science in Accountancy is a  program which provides general accounting education to learners who wish to pursue a professional career as accountants, particularly as public accountants for the civil service.",
        url: "bs-accountancy",
      },
      {
        idNum: 7,
        shortname: "BSSW",
        title: "Bachelor of Science in Social Work",
        shortdesc:
          "The Bachelor of Science in Social Work degree is designed to educate students for generalist social work practice that focuses on the reciprocal relationships between persons and their environments.",
        url: "bs-social-work",
      },
      {
        idNum: 8,
        shortname: "BSCRIM",
        title: "Bachelor of Science in Criminology",
        shortdesc:
          "The Bachelor of Science in Criminology focuses on the study of historical and contemporary patterns of crime and victimization, the social etiology of criminality, social responses to crime, and the processes of crime control.",
        url: "bs-criminology",
      },
    ],
  },
  {
    id: 3,
    title: "SHS",
    name: "Senior High School Department",
    logo: Case,
    color: "c3",
    departments: [
      {
        idNum: 9,
        shortname: "HUMMS",
        title: "Humanities and Social Sciences (HUMSS)",
        shortdesc:
          "The HUMSS Senior High School strand is designed for students who intend to take up journalism, communication arts, liberal arts, education, and other social-science related courses in college.",
        url: "",
      },
      {
        idNum: 10,
        shortname: "STEM",
        title: "Science, Technology, Engineering and Mathematics (STEM)",
        shortdesc:
          "Through the STEM strand, senior high school students are exposed to complex mathematical and science theories and concepts which will serve as a foundation for their college courses.",
        url: "",
      },
      {
        idNum: 11,
        shortname: "ABM",
        title: "Accountancy, Business and Management (ABM)",
        shortdesc:
          "The ABM strand  seeks to prepare the young business leaders of tomorrow. ABM strand paves the way for business-related college degrees. It teaches the basic concepts of financial management, accounting, and corporate operations.",
        url: "",
      },
      {
        idNum: 12,
        shortname: "TVL",
        title: "Technical, Vocational, and Livelihood (TVL)",
        shortdesc:
          "TVL Strand is designed to develop students' skills that is useful for livelihood and technical projects. It provides a curriculum that is a combination of Core Courses and specialized hands-on courses that meets the competency-based assessment of TESDA.",
        url: "",
      },
    ],
  },
];

export default Colleges;

function Card(props) {
  const data = props.data;
  return (
    <div className="w-[85%] bg-white shadow-md p-6 rounded-2xl">
      <div className="mb-4">
        <b className="text-xl lg:text-2xl text-sky-900 mb-4 lg:">
          {data.title}
        </b>
      </div>
      <div className="text-base font-bold border-b-2 border-sky-900 mb-2">
        {data.authors}
      </div>
      <div className="text-base mb-4">{data.abstract}</div>
      <div className="text-right">
        <a
          className="bg-sky-300 rounded px-4 py-2 inline-block"
          href={data.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          View PDF
        </a>
      </div>
    </div>
  );
}

export default Card;

import React, { useEffect } from "react";
import Header from "./Header";
import cover from "./res/cover1000px.jpg";
import About from "./homePageComp/About";
import Publisher from "./homePageComp/Publisher";
import Editorial from "./homePageComp/Editorial";
import Footer from "./homePageComp/Footer";

function JournalHome(props) {
  useEffect(() => {
    document.title =
      "Asian Journal of Research in Science and Technology - AJORIST";
  }, []);
  return (
    <React.Fragment>
      <Header />
      <section className="flex flex-col lg:w-[100vw] lg:flex-row m-0 border-0 outline-none ">
        <div className="w-[100vw] lg:w-[50vw]">
          <img className="" src={cover} alt="" />
        </div>
        <div className="bg-sky-800 py-10 lg:py-2 px-4 md:pl-10 text-white lg:w-[50vw] md:flex md:flex-col justify-center hover:bg-sky-200 transition-all hover:text-black">
          <div className="mb-4 font-light text-base">
            <p className="text-center md:text-left">
              We are pleased to announce the Call for Research Papers for the
              year 2023 in the{" "}
              <b className="font-bold">
                Asian Journal of Research in Science and Technology (AJORIST)
              </b>
              . As an esteemed online research journal, AJORIST aims to foster
              scientific advancements and technological innovations across
              various disciplines within the Asian region and beyond.
            </p>
            <p className="text-center mt-4  md:text-left">
              AJORIST invites researchers, scientists, academicians, and
              professionals to submit their original research papers in the
              field of science and technology.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center md:items-start">
            <p className="text-md md:text-base font-light">
              For more information, click the button below
            </p>
            <a
              className="bg-sky-300 text-black h-8 w-48 rounded-md mt-4 flex justify-center items-center hover:bg-sky-400 transition-all"
              href="/journal"
            >
              Find out more
            </a>
          </div>
        </div>
      </section>
      <About />
      <Publisher />
      <Editorial />
      <Footer />
    </React.Fragment>
  );
}

export default JournalHome;

import React from "react";
import editIcon from "../svg/edit.svg";
import deleteIcon from "../svg/delete.svg";

function Departments(props) {
  const offices = props.offices;
  return (
    <div className="h-[50%] flex flex-col gap-1 xl:h-full flex-1">
      <h3 className="text-base font-bold">Departments</h3>
      <div className="flex-1 h-auto bg-slate-100 overflow-scroll p-4 rounded-xl">
        {offices.map((val, key) => {
          return (
            <div
              className="flex flex-row border-b-2 border-b-stone-200 py-1"
              key={key}
            >
              <div className="flex-1 w-auto flex flex-col">
                <h4 className="font-bold text-base">{val.shortName}</h4>
                <p>{val.name}</p>
              </div>
              <div className="flex flex-row gap-2">
                <img
                  className="cursor-pointer"
                  src={editIcon}
                  alt=""
                  onClick={() => {
                    props.editDepartment(val.id);
                  }}
                />
                <img
                  className="cursor-pointer"
                  src={deleteIcon}
                  onClick={() => {
                    props.deleteDepartment(val.id);
                  }}
                  alt=""
                />
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <button
          className="bg-sky-300 rounded-xl py-2 px-8"
          onClick={props.newDepartment}
        >
          New Department
        </button>
      </div>
    </div>
  );
}

export default Departments;

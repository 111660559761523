import React from "react";
import logo from "../res/logo.png";
function ErrorPage() {
  return (
    <div className="flex items-center justify-center h-screen flex-col">
      <div className="h-32 animate-bounce">
        <img className="h-full" src={logo} alt="" />
      </div>
      <h1 className="text-5xl font-bold mt-8">PAGE LOADING...</h1>
      <p>Page is either loading or not available</p>
    </div>
  );
}

export default ErrorPage;

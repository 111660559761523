import React from "react";

function Admission() {
  return (
    <div className="px-8 py-8 md:w-96 md:pt-8 bg-stone-400">
      <h3 className="text-xl font-light">Admission</h3>
      <ul className="list-disc ml-4 space-y-2 font-bold">
        <li>
          <h4>Incoming Freshmen</h4>
          <ul className="list-decimal ml-4 font-normal">
            <li>Form 138 (Original Copy) high school report card </li>
            <li>Form 137 – A (may be submitted later)</li>
            <li>Certificate of Good Moral Character</li>
            <li>NSO Authenticated Birth Certificate </li>
            <li>2pcs recent 2 x 2 picture </li>
            <li>1 pc long brown envelope </li>
          </ul>
        </li>
        <li>
          <h4>Transferees</h4>
          <ul className="list-decimal ml-4 font-normal">
            <li>
              Certificate of Transfer Credentials (CTC) or Honorable Dismissal
              (HD)
            </li>
            <li>
              Informative copy of Transcript of Records (TOR) for Evaluation
              purposes
            </li>
            <li>Certificate of Good Moral Character</li>
            <li>NSO Authenticated Birth Certificate </li>
            <li>2pcs recent 2 x 2 picture </li>
            <li>1 pc long brown envelope </li>
          </ul>
          <p className="font-normal italic">
            Second Courser shall meet the requirement for admission of incoming
            freshmen.
          </p>
        </li>
        <li>
          <h4>Cross Enrollees</h4>
          <p className="font-normal">
            Permit to cross enroll from the Registrar of the mother school
            indicating the course, units, school year, and specific school to
            admit the student.
          </p>
        </li>
      </ul>
    </div>
  );
}

export default Admission;

import React, { useState } from "react";
import logo40 from "../../res/Logo/logo40px.png";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-sky-900">
      <div className="w-[100vw] lg:w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center justify-betweennpm ">
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <a
                  href="/journal"
                  className="text-gray-100 hover:text-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Home
                </a>
                <a
                  href="/journal#about"
                  className="text-gray-100 hover:text-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                >
                  About the Journal
                </a>
                <a
                  href="/journal#publisher"
                  className="text-gray-100 hover:text-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                >
                  About the Publisher
                </a>
                <a
                  href="/journal#footer"
                  className="text-gray-100 hover:text-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Contact
                </a>
                <a
                  href="/journal/latest"
                  className="text-sky-900 hover:text-gray-200 px-3 py-2 rounded-md text-sm font-medium bg-sky-300"
                >
                  Latest Issue
                </a>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="bg-sky-900 inline-flex items-center justify-center p-2 rounded-md text-gray-100 hover:text-gray-200 hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {/* Icon when menu is closed */}
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="3" y1="12" x2="21" y2="12" />
                  <line x1="3" y1="6" x2="21" y2="6" />
                  <line x1="3" y1="18" x2="21" y2="18" />
                </svg>
              ) : (
                // Icon when menu is open
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Dropdown menu for small screens */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a
              href="/journal"
              className="text-gray-100 hover:text-gray-200 block px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </a>
            <a
              href="/journal#about"
              className="text-gray-100 hover:text-gray-200 block px-3 py-2 rounded-md text-base font-medium"
            >
              About the Journal
            </a>
            <a
              href="/journal#publisher"
              className="text-gray-100 hover:text-gray-200 block px-3 py-2 rounded-md text-base font-medium"
            >
              About the Publisher
            </a>
            <a
              href="/journal#contact"
              className="text-gray-100 hover:text-gray-200 block px-3 py-2 rounded-md text-base font-medium"
            >
              Contact
            </a>
            <a
              href="/journal/latest"
              className="text-gray-100 hover:text-gray-200 block px-3 py-2 rounded-md text-base font-medium"
            >
              Latest Issue
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Header;

import { useEffect, useState } from "react";
import img1 from "../../res/bsce-se.jpg";
import img2 from "../../res/bsce-te.jpg";
import img3 from "../../res/bsce-cem.webp";
import logo from "../../res/logo.png";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebaseConfig";

function Feature(props) {
  const features2 = props.features;

  const [features, setFeatures] = useState([
    {
      title: "Loading",
      cover: logo,
      url: "#",
    },
    { title: "Title 2", cover: img2, url: "#" },
    { title: "Title 3", cover: img3, url: "#" },
  ]);
  const [activeFeat, setActiveFeat] = useState(0);
  const [activeImg, setActiveImg] = useState(features[activeFeat].cover);
  const [activeTitle, setActiveTitle] = useState(features[activeFeat].title);
  const [activeUrl, setActiveUrl] = useState(features[activeFeat].url);

  const newFeatures = () => {
    console.log(features2);

    let newFeat = [];
    for (let i = 0; i < 3; i++) {
      if (features2[i] === undefined) {
      } else {
        let newItem = {
          title: features2[i].title,
          cover: features2[i].imgurl,
          url: features2[i].url,
        };
        newFeat.push(newItem);

        setFeatures(newFeat);
      }
    }
  };
  useEffect(() => {
    newFeatures();
  }, [features2]);

  const changeImg = () => {
    switch (activeFeat) {
      case 0:
        setActiveImg(features[activeFeat].cover);
        break;
      case 1:
        setActiveImg(img2);
        break;
      case 2:
        setActiveImg(img3);
        break;
    }
  };

  const changeFeat = () => {
    switch (activeFeat) {
      case 0:
        setActiveFeat(1);
        break;
      case 1:
        setActiveFeat(2);
        break;
      case 2:
        setActiveFeat(0);
        break;
    }
  };

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === 5) {
      changeFeat();
      setSeconds(0);
    }
  }, [seconds]);

  useEffect(() => {
    setActiveImg(features[activeFeat].cover);
    setActiveTitle(features[activeFeat].title);
  }, [activeFeat]);

  return (
    <div className="h-full w-full overflow-hidden relative">
      <img src={activeImg} className="object-cover h-full w-full" alt="" />
      <div className="absolute text-2xl bottom-3 w-full text-center font-bold text-white drop-shadow-lg [text-shadow:_-2px_4px_5px_rgb(0_0_0_/_40%)]">
        <a className="hover:underline" href="">
          {activeTitle}
        </a>
      </div>
    </div>
  );
}

export default Feature;

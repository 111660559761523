import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import { HashRouter } from "react-router-dom";
//import logo from "./res/logo.png";
import Colleges from "./Colleges";

/** Pages **/
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import About from "./pages/About";
import Programs from "./pages/Programs";
import DepartmentPage from "./pages/DeparmentPage";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Admin from "./pages/Admin";
import JournalHome from "./pages/journal/JournalHome";
//import Header from "./components/Header";
//import Journals from "./pages/Journals";
import { db } from "./firebaseConfig";

//import { act } from "react-dom/test-utils";
import Latest from "./pages/journal/latestPage/Latest";
import { collection, getDoc, getDocs } from "firebase/firestore";
import News from "./pages/News";
import FeaturePage from "./pages/FeaturePage";

function App() {
  const activePageClass =
    "block py-2 pl-3 pr-4 text-white bg-sky-600 rounded md:bg-transparent md:text-sky-600 md:p-0 dark:text-white md:dark:text-sky-600";

  const inactivePageClass =
    "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-sky-600 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent";

  const [colleges, setColleges] = useState(Colleges);
  const [homeClass, setHomeClass] = useState(activePageClass);
  const [progClass, setProgClass] = useState(inactivePageClass);
  const [aboutClass, setAboutClass] = useState(inactivePageClass);
  const [journalsClass, setJournalsClass] = useState(activePageClass);

  const setHomeActive = () => {
    setHomeClass(activePageClass);
    setProgClass(inactivePageClass);
    setAboutClass(inactivePageClass);
    setJournalsClass(inactivePageClass);
  };

  const setProgActive = () => {
    setHomeClass(inactivePageClass);
    setProgClass(activePageClass);
    setAboutClass(inactivePageClass);
    setJournalsClass(inactivePageClass);
  };

  const setAboutActive = () => {
    setHomeClass(inactivePageClass);
    setProgClass(inactivePageClass);
    setAboutClass(activePageClass);
    setJournalsClass(inactivePageClass);
  };
  const setJournalsActive = () => {
    setHomeClass(inactivePageClass);
    setProgClass(inactivePageClass);
    setAboutClass(inactivePageClass);
    setJournalsClass(activePageClass);
  };
  const allInactive = () => {
    setHomeClass(inactivePageClass);
    setProgClass(inactivePageClass);
    setAboutClass(inactivePageClass);
    setJournalsClass(inactivePageClass);
  };

  const newsRef = collection(db, "news");
  const [news, setNews] = useState([
    {
      title: "title",
      date: "date",
      newsBody: "body",
      lead: "lead",
      url: "url",
    },
  ]);

  const getNews = async () => {
    const data = await getDocs(newsRef);
    setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const programsRef = collection(db, "programs");
  const [programs, setPrograms] = useState([
    {
      url: "",
    },
  ]);
  const featuresRef = collection(db, "features");
  const [features, setFeatures] = useState([
    {
      id: "",
      title: "title",
      url: "url",
      date: "date",
      imgFileName: "",
      body: "body",
    },
  ]);
  const getFeatures = async () => {
    const data = await getDocs(featuresRef);
    let featuresLoad = [];
    featuresLoad = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setFeatures(featuresLoad);
  };

  const getPrograms = async () => {
    const data = await getDocs(programsRef);
    let programsLoad = [];
    programsLoad = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setPrograms(programsLoad);
  };
  useEffect(() => {
    getNews();
    getPrograms();
    getFeatures();
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <React.Fragment>
                <div className="mainBody">
                  <NavBar
                    homeClass={homeClass}
                    progClass={progClass}
                    aboutClass={aboutClass}
                    journalsClass={journalsClass}
                  />
                  <Home setActivePage={setHomeActive} features={features} />
                  <Footer />
                </div>
              </React.Fragment>
            }
            title="PEACI"
          />
          <Route
            path="/programs"
            element={
              <React.Fragment>
                <div className="mainBody">
                  <NavBar
                    homeClass={homeClass}
                    progClass={progClass}
                    aboutClass={aboutClass}
                    journalsClass={journalsClass}
                  />
                  <Programs colleges={colleges} setActivePage={setProgActive} />
                  <Footer />
                </div>
              </React.Fragment>
            }
          />
          <Route
            path="/about"
            element={
              <React.Fragment>
                <div className="mainBody">
                  <NavBar
                    homeClass={homeClass}
                    progClass={progClass}
                    aboutClass={aboutClass}
                    journalsClass={journalsClass}
                  />
                  <About setActivePage={setAboutActive} />
                  <Footer />
                </div>
              </React.Fragment>
            }
          />
          <Route
            path="/journal"
            element={
              <React.Fragment>
                <JournalHome />
              </React.Fragment>
            }
          />
          <Route
            path="/journal/latest"
            element={
              <React.Fragment>
                <Latest />
              </React.Fragment>
            }
          />

          {programs.map((val, key) => {
            return (
              <Route
                key={key}
                path={`/${val.url}`}
                element={
                  <React.Fragment>
                    <div className="mainBody">
                      <NavBar
                        homeClass={homeClass}
                        progClass={progClass}
                        aboutClass={aboutClass}
                        journalsClass={journalsClass}
                      />
                      <DepartmentPage
                        data={val}
                        idNum={val.idNum}
                        setActivePage={setProgActive}
                      />
                      <Footer />
                    </div>
                  </React.Fragment>
                }
              />
            );
          })}

          {/* {colleges.map((data) => {
            return data.departments.map((val) => (
              <Route
                key={data.id}
                path={val.url}
                element={
                  <React.Fragment>
                    <div className="mainBody">
                      <NavBar
                        homeClass={homeClass}
                        progClass={progClass}
                        aboutClass={aboutClass}
                        journalsClass={journalsClass}
                      />
                      <DepartmentPage
                        data={val}
                        idNum={val.idNum}
                        setActivePage={setProgActive}
                      />
                      <Footer />
                    </div>
                  </React.Fragment>
                }
              />
            ));
          })} */}

          {news.map((val, key) => {
            return (
              <Route
                key={key}
                path={val.url}
                element={
                  <React.Fragment>
                    <div className="mainBody">
                      <NavBar
                        homeClass={homeClass}
                        progClass={progClass}
                        aboutClass={aboutClass}
                        journalsClass={journalsClass}
                      />
                      <News data={val} news={news} />
                      <Footer />
                    </div>
                  </React.Fragment>
                }
              />
            );
          })}

          {features.map((val, key) => {
            return (
              <Route
                key={key}
                path={val.url}
                element={
                  <React.Fragment>
                    <div className="mainBody">
                      <NavBar
                        homeClass={homeClass}
                        progClass={progClass}
                        aboutClass={aboutClass}
                        journalsClass={journalsClass}
                      />
                      <FeaturePage data={val} features={features} />
                      <Footer />
                    </div>
                  </React.Fragment>
                }
              />
            );
          })}

          <Route path="*" element={<ErrorPage />} />
          <Route path="/webadmin" element={<Admin />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;

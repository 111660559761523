import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../homePageComp/Footer";
import issueData from "./issueData";
import Card from "./Card";

function Latest() {
  useEffect(() => {
    document.title = "AJORIST - Latest";
  }, []);
  return (
    <React.Fragment>
      <Header />
      <main className="py-8 bg-sky-50 flex justify-center">
        <div className="px-2 w-[100%] lg:w-[1000px]">
          <div className="text-center text-2xl">
            <b className="text-3xl">Volume 1</b>, Issue 1 -{" "}
            <b className="text-3xl text-sky-900">AJORIST</b>
          </div>
          <div className="mt-4 flex flex-col items-center gap-4">
            {issueData.map((val, key) => {
              return <Card key={key} data={val} />;
            })}
          </div>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default Latest;

import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import Colleges from "./collegesComps/Colleges";
import Departments from "./collegesComps/Departments";
import CollegesNew from "./collegesComps/CollegesNew";
import CollegesEdit from "./collegesComps/CollegesEdit";
import DepartmentNew from "./collegesComps/DepartmentNew";
import DepartmentEdit from "./collegesComps/DepartmentEdit";
import DelAlert from "./newsComps/DelAlert";

function CollegesAdmin() {
  const officesRef = collection(db, "offices");
  const programsRef = collection(db, "programs");
  const [offices, setOffices] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [activeOffice, setActiveOffice] = useState(0);
  const [activeDepartment, setActiveDepartment] = useState(0);
  const [loadingProgramm, setLoadingProgram] = useState(false);
  const [activeNewCollege, setActiveNewCollege] = useState(false);
  const [activeEditCollege, setActiveEditCollege] = useState(false);
  const [activeNewDepartment, setActiveNewDepartment] = useState(false);
  const [activeEditDepartment, setActiveEditDepartment] = useState(false);
  const [activeDelete, setActiveDelete] = useState(false);
  let officesLoad = [];
  let programsLoad = [];

  const getOffices = async () => {
    const data = await getDocs(officesRef);
    officesLoad = data.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }))
      .sort((a, b) => {
        let da = a.idOffice;
        let db = b.idOffice;
        return da - db;
      });
    setOffices(officesLoad);
    //console.log(officesLoad);
  };

  const getPrograms = async () => {
    const data = await getDocs(programsRef);
    programsLoad = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setPrograms(programsLoad);
    //console.log(programsLoad);
    let newArr = [...programsLoad];
    //console.log(newArr);
    let activeProgram = newArr.filter((el) => {
      // eslint-disable-next-line
      return el.idOffice == activeOffice;
    });
    //console.log(activeProgram);
    setPrograms(activeProgram);
    setLoadingProgram(false);
  };

  const getActivePrograms = (idoffice) => {
    setLoadingProgram(true);
    //console.log("click ", idoffice);
    setActiveOffice(idoffice);
  };

  useEffect(() => {
    getPrograms();
    // eslint-disable-next-line
  }, [activeOffice]);

  useEffect(() => {
    getOffices();
    //getPrograms();
    //console.log(officesLoad);
    // eslint-disable-next-line
  }, []);

  const confirmDelete = async () => {
    const programsDoc = doc(db, "programs", programs[activeDepartment].id);
    await deleteDoc(programsDoc);
    alert("Department Deleted");
    setActiveDelete(false);
    getPrograms();
  };

  const deleteProgramPrompt = (id) => {
    const activeDepartmentIndex = programs.findIndex((obj) => {
      // eslint-disable-next-line
      return obj.id == id;
    });
    setActiveDepartment(activeDepartmentIndex);
    setActiveDelete(true);
  };

  const renderLoadingProgram = () => {
    return loadingProgramm ? (
      <div className="flex justify-center items-center">
        <div className="animate-bounce">Loading</div>
      </div>
    ) : (
      <Departments
        offices={programs}
        editDepartment={editDepartment}
        deleteDepartment={deleteProgramPrompt}
        newDepartment={() => {
          setActiveNewDepartment(true);
        }}
      />
    );
  };

  const renderNewCollegeForm = () => {
    return activeNewCollege ? (
      <CollegesNew
        close={() => {
          setActiveNewCollege(false);
        }}
      />
    ) : (
      ""
    );
  };

  const renderEditCollegeForm = () => {
    const activeOfficeIndex = offices.findIndex((obj) => {
      return obj.idOffice === activeOffice;
    });

    return activeEditCollege ? (
      <CollegesEdit
        close={() => {
          setActiveEditCollege(false);
        }}
        office={offices[activeOfficeIndex]}
        refresh={getOffices}
        activeOffice={activeOffice}
      />
    ) : (
      ""
    );
  };

  const editDepartment = (id) => {
    const activeDepartmentIndex = programs.findIndex((obj) => {
      // eslint-disable-next-line
      return obj.id == id;
    });
    setActiveDepartment(activeDepartmentIndex);
    setActiveEditDepartment(true);
    getPrograms();
  };

  const renderNewDepartmentForm = () => {
    return activeNewDepartment ? (
      <DepartmentNew
        close={() => {
          setActiveNewDepartment(false);
        }}
        offices={offices}
      />
    ) : (
      ""
    );
  };

  const renderEditDepartment = () => {
    return activeEditDepartment ? (
      <DepartmentEdit
        close={() => {
          setActiveEditDepartment(false);
        }}
        data={programs[activeDepartment]}
        offices={offices}
      />
    ) : (
      ""
    );
  };

  const renderDeleteProgram = () => {
    return activeDelete ? (
      <DelAlert
        title="department"
        confirm={confirmDelete}
        cancel={() => {
          setActiveDelete(false);
        }}
      />
    ) : (
      ""
    );
  };

  return (
    <React.Fragment>
      <div className="bg-white w-[100%] h-[100%] p-2 rounded-xl shadow-md flex flex-col">
        <h1 className="font-bold text-2xl">Colleges and Programs</h1>
        <div className="flex flex-1 flex-col gap-2 h-auto  xl:flex-row">
          <Colleges
            offices={offices}
            getPrograms={getActivePrograms}
            newCollege={() => {
              setActiveNewCollege(true);
            }}
            editCollege={() => {
              setActiveEditCollege(true);
            }}
          />
          {renderLoadingProgram()}
        </div>
      </div>
      {renderEditCollegeForm()}
      {renderNewCollegeForm()}
      {renderNewDepartmentForm()}
      {renderEditDepartment()}
      {renderDeleteProgram()}
    </React.Fragment>
  );
}

export default CollegesAdmin;

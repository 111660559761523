import { updateDoc, doc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { db, storage } from "../../firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import FileResizer from "react-image-file-resizer";

function CollegesEdit(props) {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState("");
  const [name, setName] = useState(props.office.name);
  const [shortName, setShortName] = useState(props.office.shortName);
  const [idOffice, setIdOffice] = useState(props.office.idOffice);
  let url;

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const addNewNews = async (id) => {
    const officesDoc = doc(db, "offices", id);
    const officesInput = {
      name: name,
      shortName: shortName,
      idOffice: idOffice,
      logoUrl: url,
    };
    await updateDoc(officesDoc, officesInput);
    props.refresh();
    alert("News Updated");

    props.close();
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        300, //maxWidth
        300, //maxHeight
        "PNG",
        100, //quality
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const uploadFile = (file) => {
    const storageRef = ref(storage, `/files/images/${shortName}.png`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(prog);
        if (prog === 100) {
          getDownloadURL(storageRef)
            .then((res) => {
              url = res;
            })
            .then(() => {
              addNewNews(props.office.id);
            });
        }
      },
      (err) => {
        console.log(err);
      }
    );
    console.log(props.office.id);
  };

  const formHandler = async (e) => {
    try {
      e.preventDefault();
      let file = e.target[0].files[0];
      if (!file) {
        console.log(props.office.id);
        addNewNews(props.office.id);
      } else {
        const image = await resizeFile(file);
        uploadFile(image);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="absolute w-full h-full m-0 p-0 border-0 top-0 left-0">
      <div className="fixed w-screen h-screen bg-slate-800 opacity-20"></div>
      <div className="fixed w-screen h-screen p-8 flex">
        <div className="relative w-full h-full bg-white rounded-2xl p-4 overflow-y-scroll">
          <h2 className="text-xl font-bold">New College</h2>

          <form className="flex flex-col gap-2" onSubmit={formHandler}>
            <h3>Logo:</h3>
            <div className="flex items-center justify-center w-full">
              <div className="border-2 border-sky-600 w-36 h-36 flex justify-center items-center overflow-clip">
                <img className="h-full object-cover" src={preview} alt="" />
              </div>
            </div>
            <div className="flex flex-col gap-2 items-center">
              <div
                className={`mt-2 h-1 w-full bg-neutral-200 dark:bg-neutral-600`}
              >
                <div className={`h-1 bg-sky-600 w-[12%]`}></div>
              </div>
              <input
                className="block rounded-full bg-stone-200 w-full file:rounded-full file:bg-sky-700"
                type="file"
                name=""
                id=""
                accept="image/*"
                onChange={onSelectFile}
              />
            </div>
            <div className="border-t-2 border-stone-400 py-2">
              <h3>Information:</h3>
              <div className="w-full flex flex-col gap-2">
                <div className="w-full flex flex-col items-center mb-2">
                  <label className="w-full" htmlFor="">
                    Office Name:{" "}
                  </label>
                  <input
                    className="rounded-full px-4 w-full"
                    type="text"
                    placeholder="College of Information Technology and Engineering"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
                <div className="w-full flex flex-col items-center mb-2">
                  <label className="w-full" htmlFor="">
                    Abbreviation:{" "}
                  </label>
                  <input
                    className="rounded-full px-4 w-full"
                    type="text"
                    placeholder="CITE"
                    onChange={(e) => setShortName(e.target.value)}
                    value={shortName}
                  />
                </div>
                <div className="w-full flex flex-col   items-center ">
                  <label className="w-full" htmlFor="">
                    Number Order:{" "}
                  </label>
                  <input
                    className="rounded-full px-4 w-full"
                    type="number"
                    placeholder="0"
                    onChange={(e) => setIdOffice(e.target.value)}
                    value={idOffice}
                  />
                </div>
              </div>
            </div>
            <button
              className="bg-sky-600 text-white font-bold py-2 rounded-full"
              type="submit"
            >
              Update College/Office
            </button>
          </form>
          <div className="flex flex-row justify-end w-full"></div>
          <button
            className="absolute top-2 right-2 bg-rose-600 py-2 px-4 rounded-full text-white font-bold "
            onClick={props.close}
          >
            X
          </button>
        </div>
      </div>
    </div>
  );
}

export default CollegesEdit;

const issueData = [
  {
    id: 1,
    title:
      "Teaching Practices and Challenges of Elementary Private Teachers in Modular Distance Learning",
    authors: "Jorry Q. Maruhom",
    abstract:
      "This study focuses on the teaching practices and challenges of elementary private teachers in modular distance learning. Using mixed method design, an adapted survey questionnaire with 3 parts: demographic proÞle of the respondents, teaching practices, and challenges encountered was Þrst given to the Þfty (50) selected elementary teachers from four (4) different private schools in Marawi City, Philippines. Subsequently, an interview was carried out among the respondents to expose the other challenges encountered by the respondents in modular distance learning. With frequency and percentage distribution and mean and standard deviation as statistical tools used to analyze the data collected, the Þndings reflect that as a teaching practice, the respondents prioritize their students' interests when they draft their modules. They also give sufficient opportunities to their students' understanding about the modules they make. In terms of challenges, the respondents expose that communication with the students in modular distance learning is difficult. Furthermore, it was also revealed that students are not able to answer all questions in their modules.The results of the study suggest that teachers should be able to include only the most essential subject contents in their modules to address the problem of incomplete answers and to prioritize the basic interest of the students.",
    url: "https://drive.google.com/file/d/1wc100n31L0NB-baP1iMHykXiSf0w_ILa/view?usp=share_link",
  },
  {
    id: 2,
    title:
      "Perceived Effect of Procrastination to Academic Performance of Civil Engineering Students during Blended Learning",
    authors: "Norlaillah M. Nasrola, Animah D. H. Edris, & Klient M. Mahilum",
    abstract:
      "This study focuses on the teaching practices and challenges of elementary private teachers in modular distance learning. Using mixed method design, an adapted survey questionnaire with 3 parts: demographic proÞle of the respondents, teaching practices, and challenges encountered was Þrst given to the Þfty (50) selected elementary teachers from four (4) different private schools in Marawi City, Philippines. Subsequently, an interview was carried out among the respondents to expose the other challenges encountered by the respondents in modular distance learning. With frequency and percentage distribution and mean and standard deviation as statistical tools used to analyze the data collected, the Þndings reflect that as a teaching practice, the respondents prioritize their students’ interests when they draft their modules. They also give sufficient opportunities to their students’ understanding about the modules they make. In terms of challenges, the respondents expose that communication with the students in modular distance learning is difÞcult. Furthermore, it was also revealed that students are not able to answer all questions in their modules. The results of the study suggest that teachers should be able to include only the most essential subject contents in their modules to address the problem of incomplete answers and to prioritize the basic interest of the students.",
    url: "https://drive.google.com/file/d/16B1jQatZ7L1w1fnvQ1nL-XoE5EI0E_Dk/view?usp=share_link",
  },
  {
    id: 3,
    title:
      "Compressive Strength of Concrete Containing Coconut Shell as Coarse Aggregates",
    authors:
      "Ali-Hassan A. Usman, Abdul Jabber B. Dimal, Allani R. Tanggote, Abdul Aziz O. Tomindug, & Mohammad S. Malik ",
    abstract:
      "Concrete is a premier construction material consisting of natural aggregate. Due to rapid industrialization and construction in developing countries like the Philippines, natural resources are depleting constantly. The search for alternative materials for making concrete is a prime need in the present scenario. Environmental issues, such as restrictions on local and natural access to and sources of waste material, are gaining great importance. The waste coconut shell may be utilized to replace natural coarse aggregate. In the present study, the compressive strength of concrete of M–20 grades was studied by replacing natural coarse aggregates at 0%, 25%, 50%, 75%, and 100% by weight with coconut shell. The compressive strength of coconut shell concrete has been evaluated after 28 days. The compressive strength of coconut shell concrete was reduced as the percentage replacement increased. The concrete mixtures were tested and compared in terms of compressive strength with conventional concrete. The study result shows that Coconut Shell Concrete (CSC) can be used as a lightweight concrete. Use of coconut shell as a substitute for aggregate will not only be cost-effective and eco-friendly but also help to resolve the problem of a shortage of conventional material such as coarse aggregate. The use of such materials also reduces the problem of disposing of waste material. ",
    url: "https://drive.google.com/file/d/1bg6TtgKOM6QdZeN01TNfgVJQoQ8dAO1i/view?usp=share_link",
  },
  {
    id: 4,
    title:
      "Compressive Strength and Shrinkage of Plain Concrete Containing Varying Amounts of Glass as Replacement Fine Aggregates",
    authors:
      "Fatih R. Alibsar, Rayyan P. Alikhan, Alkhyre P. Caderan, Mohammad yasin a. Madale, & Jabbar B. Maki",
    abstract:
      "This study aimed to determine the effect of using waste crushed glass as a replacement for Þne aggregates affected the compressive strength and shrinkage of plain concrete. Type 1 Portland Cement makes twelve (4x8) cylindrical samples with a Þne aggregate proportion of 1:2 and a water-cement ratio of 0.49. The validation was based on the variation in the shrinkage of the moisture content of plain concrete and its compressive strength. The study concluded that the compressive strength of plain cylindrical concrete is highly affected by the use of varying waste crushed glass content as a substitute for Þne aggregates. The output results of shrinkage on the specimen were determined using a moisture content test and a physical observation test. The twelve (12) specimens with 36 hours of oven drying have small dark spots, changing the texture and colors and decreasing the weight of the sample specimen. Thus, using glass as a substitute is not advisable because it reduces the strength of the concrete and its moisture content. ",
    url: "https://drive.google.com/file/d/1NLlmQgJjPdXT7SLXH80a-Z1cdXyTnQo8/view?usp=share_link",
  },
  {
    id: 5,
    title:
      "Compressive Strength of Concrete Hollow Blocks Made from Concrete Mixed with Sahara Waterproofing Cement",
    authors:
      "Mobarac A. Abubacar, Nadeem B. Baraocor, Johary P. Acmad, Rayhanisa B. Compa, Amina Zahriah L. Farouk, & Yasmen M. Hadji Abdulgani",
    abstract:
      "The use of concrete in construction has been growing, leading to the development of new admixtures to enhance its properties. This study evaluated the impact of different admixtures on the engineering properties of concrete hollow blocks. The researchers found that the addition of Sahara Waterproofing Cement signiÞcantly increased the compressive strength of the blocks. In conclusion, this study highlights the potential of Sahara Waterproofing Cement as an admixture that can improve the compressive strength of concrete and provides valuable insights for engineers and construction professionals looking to enhance the engineering properties of concrete. Based on these results, the following recommendations were made: concrete hollow block manufacturers should consider the trade-off between the waterprooÞng properties provided by Sahara Waterproofing Cement and the potential decrease in compressive strength when determining the appropriate mix ratio for their products. Further research is suested on alternative waterproofing methods or additives that may provide similar waterproofing beneÞts without compromising the strength of the resulting concrete blocks.",
    url: "https://drive.google.com/file/d/1dpsJvnKcV_VoYpqoi89LltirN2xwDhGv/view?usp=share_link",
  },
];

export default issueData;

import React, { useState, useEffect } from "react";
import AdminHome from "../adminComponents/AdminHome";
import AdminLogin from "../adminComponents/AdminLogin";

function Admin(props) {
  const [auth, setAuth] = useState(false);
  const [activeComp, setActiveComp] = useState(0);

  const checkAuth = () => {};

  const login = () => {
    setAuth(true);
  };

  const logout = () => {
    setAuth(false);
  };

  const renderComp = (auth) => {
    switch (auth) {
      case false:
        return <AdminLogin login={login} />;
      case true:
        return <AdminHome logout={logout} />;
      default:
        return <div>Error!</div>;
    }
  };

  return <div className="webMain">{renderComp(auth)}</div>;
}

export default Admin;

import React, { useState, useEffect } from "react";

function Departments(props) {
  const data = props.data;

  const renderLink = () => {
    if (data.url != "") {
      return <a href={`../${data.url}`}>FIND OUT MORE</a>;
    }
  };

  return (
    <div>
      <h3 className="text-2xl md:text-xl mb-5 font-bold">{data.degree}</h3>
      <div className="mb-10 text-xl md:text-base">{data.shortDesc}</div>
      <div className="mb-10">{renderLink()}</div>
    </div>
  );
}

export default Departments;

import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import React, { useState, useEffect } from "react";
import logo from "../res/logo.png";

function AdminLogin(props) {
  const userRef = collection(db, "adminLogin");
  const [loginLoading, setLoginLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [passInput, setPassInput] = useState("");

  let userLogin;

  const renderLoading = () => {
    if (loginLoading) {
      return (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      );
    } else {
      return (
        <Button onClick={login} variant="contained">
          Login
        </Button>
      );
    }
  };

  const getAdminLogin = async () => {
    const data = await getDocs(userRef);
    userLogin = data.docs.map((doc) => ({ ...doc.data() }))[0];
  };

  useEffect(() => {
    document.title = "Admin Login";
  }, []);

  const renderBounce = () => {
    return loginLoading ? "animate-bounce" : "";
  };

  const login = () => {
    setLoginLoading(true);
    getAdminLogin((res) => {})
      .then(() => {
        setLoginLoading(false);
      })
      .then(() => {
        if (userInput === userLogin.username) {
          passInput === userLogin.password
            ? props.login()
            : alert("wrong password");
        } else {
          alert("username not found");
        }
        userLogin = [];
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      login();
    }
  };

  return (
    <div className="loginPage">
      <div className="loginLogoDiv">
        <img className={renderBounce()} src={logo} alt="" />
      </div>
      <TextField
        label="Username"
        variant="outlined"
        onChange={(e) => {
          setUserInput(e.target.value);
        }}
        onKeyDown={handleKeyDown}
      />
      <TextField
        label="Password"
        variant="outlined"
        type="password"
        onChange={(e) => {
          setPassInput(e.target.value);
        }}
        onKeyDown={handleKeyDown}
      />
      {renderLoading()};
    </div>
  );
}

export default AdminLogin;

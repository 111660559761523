import React from "react";

function NewsCardBuffer(props) {
  return (
    <React.Fragment>
      <a
        href={props.url}
        className="flex flex-col bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 mb-8"
      >
        <div className="flex flex-col justify-between p-4 leading-normal">
          <span className="bg-gray-300 w-72 h-6 rounded-xl animate-pulse mb-4"></span>
          <span className="bg-gray-200 w-64 h-4 rounded-xl animate-pulse mb-2"></span>
          <span className="bg-gray-200 w-40 h-4 rounded-xl animate-pulse mb-2"></span>
        </div>
      </a>
    </React.Fragment>
  );
}

export default NewsCardBuffer;

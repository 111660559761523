import React from "react";
import editIcon from "../svg/edit.svg";
import deleteIcon from "../svg/delete.svg";

function Colleges(props) {
  const offices = props.offices;
  return (
    <div className="h-[50%] flex flex-col gap-1 xl:h-full flex-1">
      <h3 className="text-base font-bold">Colleges</h3>
      <div className="flex-1 h-auto bg-slate-100 overflow-y-scroll overflow-x-hidden p-2 rounded-xl">
        {offices.map((val, key) => {
          return (
            <div
              className="flex flex-row border-b-2 gap-1 border-b-stone-200 p-2 rounded-xl hover:bg-sky-200 cursor-pointer"
              key={key}
            >
              <div
                className="flex-1 w-auto flex flex-col"
                onClick={() => {
                  props.getPrograms(val.idOffice);
                }}
              >
                <h4 className="font-bold text-base">{val.shortName}</h4>
                <p>{val.name}</p>
              </div>
              <div className="flex flex-row gap-2">
                <img
                  className="cursor-pointer"
                  src={editIcon}
                  onClick={() => {
                    props.getPrograms(val.idOffice);
                    props.editCollege();
                  }}
                  alt=""
                />
                <img className="cursor-pointer" src={deleteIcon} alt="" />
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <button
          className="bg-sky-300 rounded-xl py-2 px-8"
          onClick={props.newCollege}
        >
          New College
        </button>
      </div>
    </div>
  );
}

export default Colleges;

function Editorial() {
  return (
    <section className="bg-sky-50 py-12  flex justify-center">
      <div className="flex flex-col w-[1000px]">
        <div>
          <div className="text-4xl font-light text-sky-900 mb-4 text-center lg:text-left lg:text-6xl lg:mb-8">
            EDITORIAL BOARD
          </div>
        </div>
        <div className="text-center flex flex-col gap-4 lg:text-left items-center lg:items-start">
          <div>
            <p className="text-xl lg:text-2xl">Editor-in-Chief</p>
            <p className="text-3xl lg:text-4xl font-light text-sky-900">
              Dr. Rasid M. Paca
            </p>
          </div>
          <div className="flex flex-col gap-2 lg:flex-row lg:gap-12">
            <div className="w-[400px]">
              <p className="text-xl">Associate Editors</p>
              <p className="text-2xl lg:text-3xl font-light text-sky-900 ">
                Dr. Abdani D. Bandera
              </p>
              <p className="text-2xl lg:text-3xl font-light text-sky-900">
                Dr. Anabelie V. Valdez
              </p>
            </div>
            <div className="w-[400px]">
              <p className="text-xl">Managing Editor</p>
              <p className="text-2xl lg:text-3xl font-light text-sky-900">
                Dr. Abdulhaq R. Ditucalan, H.C.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2 lg:flex-row lg:gap-12">
            <div className="w-[400px]">
              <p className="text-xl">Advisory Board</p>
              <p className="text-2xl lg:text-3xl font-light text-sky-900">
                Dr. Shahid Q. Manalundong
              </p>
              <p className="text-2xl lg:text-3xl font-light text-sky-900">
                Dr. Jawad Z. Salic
              </p>
            </div>
            <div className="w-[400px]">
              <p className="text-xl">Assistant Editors</p>
              {/* <p className="text-2xl lg:text-3xl font-light text-sky-900">
                Jamzien M. Umpa
              </p>
              <p className="text-2xl lg:text-3xl font-light text-sky-900">
                Jake B. Doloricon
              </p> */}
              <p className="text-2xl lg:text-3xl font-light text-sky-900">
                Johara C. Bayabao
              </p>
              <p className="text-2xl lg:text-3xl font-light text-sky-900">
                Mellhabib M. Angni
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div>
              <p className="text-xl">Web Developer</p>
              <p className="text-2xl lg:text-3xl font-light text-sky-900">
                Mellhabib M. Angni
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Editorial;

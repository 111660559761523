import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect, useState } from "react";
import FileResizer from "react-image-file-resizer";
import { db, storage } from "../firebaseConfig";
import { addDoc, collection, deleteDoc, getDocs } from "firebase/firestore";
import logo40px from "../res/Logo/logo40px.png";
import { doc } from "firebase/firestore";

function FeaturedAdmin() {
  const featuresRef = collection(db, "features");
  const [addIsActive, setAddIsActive] = useState(true);
  const [editIsActive, setEditIsActive] = useState(false);
  const [saveIsActive, setSaveIsActive] = useState(false);
  const [delIsActive, setDelIsActive] = useState(false);
  const [cancelIsActive, setCancelIsActive] = useState(false);
  const [formIsActive, setFormIsActive] = useState(false);
  const [activeFeature, setActiveFeature] = useState("");
  const [titleInput, setTitleInput] = useState("");
  const [dateInput, setDateInput] = useState("");
  const [bodyInput, setBodyInput] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [imgInput, setImgInput] = useState("");
  const [newOrEdit, setNewOrEdit] = useState(0);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imgFileName, setImgFileName] = useState("");
  const [imgUrlInput, setImgUrlInput] = useState("");

  const getFeatures = async () => {
    const data = await getDocs(featuresRef);
    //console.log(data.docs);
    setFeatures(
      data.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => {
          let da = new Date(a.date);
          let db = new Date(b.date);
          return db - da;
        })
    );
  };

  useEffect(() => {
    getFeatures();
    //console.log(features);
  }, []);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        1024,
        1024,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const addNewFeature = async () => {
    setLoading(true);

    await addDoc(
      featuresRef,
      {
        title: titleInput,
        date: dateInput,
        body: bodyInput,
        imgFileName: imgFileName,
        url: titleInput.replaceAll(" ", "-"),
        imgurl: imgUrlInput,
      },
      alert("Added"),
      setAddIsActive(true),
      setSaveIsActive(false),
      setFormIsActive(false),
      setLoading(false)
      //setLoading(false)
    );
  };

  const uploadImage = (file, imgFileName) => {
    if (!file) return;
    const storageRef = ref(storage, `files/images/feature/${imgFileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on("state_changed", (snapshot) => {
      const prog = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      console.log(prog);
      setUploadProgress(prog);
      if (prog == 100) {
        alert("Upload Succesful!");
        getDownloadURL(snapshot.ref).then((res) => {
          console.log(res);
          setImgUrlInput(res);
        });

        setLoading(false);
      }
    });
  };

  const newFeature = async (imgFile) => {
    setLoading(true);
    let imgFileNameGen = (Math.random() + 1).toString(36).substring(7); //Random string Generator
    const fileName = `${imgFileNameGen}.jpg`;
    //let newFeatureImgRef;

    try {
      const image = await resizeFile(imgFile);
      uploadImage(image, fileName);
      console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  const editFeature = async (imgFile) => {
    alert("Editing...");
    try {
      //let file = e.target[0].files[0];
      //const image = await resizeFile(file);
    } catch (err) {
      console.log(err);
    }
  };

  const uploadFormHandler = async (e) => {
    e.preventDefault();
    let blankInput = false;
    for (let i = 0; i < e.target.length; i++) {
      console.log(e.target[i].value);
      if (!e.target[i].value) {
        blankInput = true;
      }
    }
    if (blankInput) {
      alert("Do not leave any field blank");
      return;
    }
    const imgFile = e.target[1].files[0];
    console.log(imgFile);
    if (e.target[1].files.length === 0) {
      alert("No File Selected");
    } else {
      console.log(activeFeature);
      activeFeature ? alert("Edit Feature Under Maintenance") : addNewFeature();
    }
  };

  const deleteFeature = async () => {
    console.log(activeFeature);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this?"
    );
    if (confirmDelete === true) {
      if (features.length <= 3) {
        alert("There should atleast be 3 features left");
      } else {
        const featuresDoc = doc(db, "features", activeFeature);
        await deleteDoc(featuresDoc);
        alert("Feature Deleted!");
        await getFeatures();
      }
    }
  };

  const addButtonHandler = () => {
    setAddIsActive(false);
    setSaveIsActive(true);
    setFormIsActive(true);
    setCancelIsActive(true);
  };

  const cancelButtonHandler = () => {
    setTitleInput("");
    setDateInput("");
    setBodyInput("");
    setImgUrl("");
    setFormIsActive(false);
    setAddIsActive(true);
    setSaveIsActive(false);
    setCancelIsActive(false);
    setEditIsActive(false);
    setDelIsActive(false);
  };

  const editActive = (val) => {
    const storageRef = ref(storage, `files/images/feature/${val.imgFileName}`);
    cancelButtonHandler();
    setTitleInput(val.title);
    setDateInput(val.date);
    setBodyInput(val.body);
    setActiveFeature(val.id);
    getDownloadURL(storageRef).then((res) => {
      console.log(res.blob);
      setImgUrl(res);
      //console.log(URL.createObjectURL(res));
    });
    setCancelIsActive(true);
    setEditIsActive(true);
    setDelIsActive(true);
  };

  const editButtonHandler = () => {
    setAddIsActive(false);
    setSaveIsActive(true);
    setFormIsActive(true);
    setCancelIsActive(true);
    setDelIsActive(true);
    setEditIsActive(false);
  };

  return (
    <div className="bg-white w-[100%] h-[100%] p-2 rounded-xl shadow-md flex flex-col">
      <h1 className="font-bold text-2xl">Featured News</h1>
      <div className="flex flex-col h-full gap-4 xl:flex-row">
        <div className="flex flex-col flex-1 h-auto">
          <h2 className="text-base font-bold">List</h2>
          <div className="flex-1 bg-slate-200 p-2">
            {features.map((val, key) => {
              return (
                <div
                  className="border-b-2 border-slate-400 py-1 font-bold rounded hover:bg-sky-200 transition-all hover:cursor-pointer"
                  key={key}
                  onClick={() => {
                    editActive(val);
                  }}
                >
                  {val.title}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex-1 h-auto flex flex-col">
          <h2 className="text-base font-bold">Details</h2>
          <form
            className="flex-1 bg-slate-200 p-2 flex flex-col gap-2 relative"
            onSubmit={uploadFormHandler}
          >
            <div className="flex flex-col w-full">
              <label>Title:</label>
              <input
                className="disabled:bg-neutral-200"
                type="text"
                name=""
                id=""
                disabled={!formIsActive}
                value={titleInput}
                onChange={(e) => {
                  setTitleInput(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-4">
              <div className="w-full h-32 bg-sky-400 rounded-lg">
                <img
                  className={`w-full h-full object-cover rounded-lg ${
                    imgUrl ? "visible" : "hidden"
                  }`}
                  src={imgUrl}
                />
              </div>
              <div>
                <input
                  className="rounded-xl bg-stone-200 w-full disabled:hidden"
                  type="file"
                  name=""
                  id=""
                  accept="image/jpeg"
                  disabled={!formIsActive}
                  onChange={(e) => {
                    //console.log(URL.createObjectURL(e.target.files[0]));
                    console.log(e.target.value);
                    console.log(e.target.files[0]);
                    newFeature(e.target.files[0]);
                    setImgUrl(URL.createObjectURL(e.target.files[0]));
                    setImgInput(e.target.value);
                  }}
                  value={imgInput}
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <label>Date:</label>
              <input
                className="disabled:bg-neutral-200"
                type="date"
                name=""
                id=""
                disabled={!formIsActive}
                value={dateInput}
                onChange={(e) => {
                  setDateInput(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <label>Body:</label>
              <textarea
                className="resize-none flex-1 h-full disabled:bg-neutral-200"
                disabled={!formIsActive}
                value={bodyInput}
                onChange={(e) => {
                  setBodyInput(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="absolute bottom-0 left-0 w-full bg-slate-200 flex flex-row justify-center items-centers gap-2 h-10">
              <input
                className="bg-sky-800 w-16 h-8 text-white font-bold rounded-lg disabled:opacity-25"
                onClick={addButtonHandler}
                disabled={!addIsActive}
                type="button"
                value="Add"
              />
              <input
                className="bg-sky-800 w-16 h-8 text-white font-bold rounded-lg disabled:opacity-25"
                disabled={!editIsActive}
                value="Edit"
                type="button"
                onClick={editButtonHandler}
              />
              <input
                className="bg-lime-800 w-16 h-8 text-white font-bold rounded-lg disabled:opacity-25"
                disabled={!saveIsActive}
                type="submit"
                value="Save"
              />
              <input
                className="bg-red-800 w-16 h-8 text-white font-bold rounded-lg disabled:opacity-25"
                onClick={deleteFeature}
                disabled={!delIsActive}
                value="Delete"
                type="button"
              />
              <input
                className="bg-red-800 w-16 h-8 text-white font-bold rounded-lg disabled:opacity-25"
                disabled={!cancelIsActive}
                value="Cancel"
                type="button"
                onClick={cancelButtonHandler}
              />
            </div>
          </form>
        </div>
      </div>
      <div
        className={`h-screen w-screen fixed top-0 left-0 items-center flex-col justify-center ${
          loading ? "flex" : "hidden"
        }`}
      >
        <div className="h-screen w-screen fixed bg-stone-600 opacity-60"></div>
        <img className="z-10 animate-bounce" src={logo40px} alt="" />
        <div className="z-10">Uploading ({uploadProgress}%)</div>
      </div>
    </div>
  );
}

export default FeaturedAdmin;

//import { DomainVerification } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { storage, db } from "../firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, updateDoc, doc, getDocs } from "firebase/firestore";
import FileResizer from "react-image-file-resizer";

function Landing() {
  const loginRef = collection(db, "adminLogin");
  const landingRef = collection(db, "landingtext");
  let coverRef = ref(storage, "files/images/dpThumb.jpg");
  let loginDetailsDb;
  let progressbarValue = 50;
  const [progress, setProgress] = useState(0);
  const [coverUrl, setCoverUrl] = useState(
    "http://localhost:3000/static/media/logo.bbd43f561d3fc0429b9e.png"
  );
  const [progressVisible, setProgressVisible] = useState("block");
  const [coverLoading, setCoverLoading] = useState(false);
  const [UsernameInput, setUsernameInput] = useState("");
  const [passInput, setPassInput] = useState("");
  const [passVerify, setPassVerify] = useState("");
  const [landingText, setLandingText] = useState([
    {
      id: "",
      text: "",
    },
  ]);
  const [landingInput, setLandinginput] = useState("");

  const getAdminLogin = async () => {
    const data = await getDocs(loginRef);
    const loginDetails = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    loginDetailsDb = loginDetails[0].id;
    setUsernameInput(loginDetails[0].username);
  };

  const getLandingText = async () => {
    const data = await getDocs(landingRef);
    setLandingText(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    const land = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setLandinginput(land[0].text);
  };

  useEffect(() => {
    getAdminLogin();
    getLandingText();
    setCoverLoading(true);
    getDownloadURL(coverRef)
      .then((res) => {
        setCoverUrl(res);
      })
      .then(() => {
        setCoverLoading(false);
      });
  }, []);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        1024, //maxWidth
        1024, //maxHeight
        "JPEG",
        100, //quality
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const resizeFileThumb = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        350, //maxWidth
        350, //maxHeight
        "JPEG",
        100, //quality
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const uploadFileCompressed = (file) => {
    if (!file) return;
    const storageRef = ref(storage, `/files/images/dp.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(prog);
        progressbarValue = prog;
        setProgress(prog);
        if (prog === 100) {
          setProgressVisible("hidden");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const uploadFileThumb = (file) => {
    if (!file) return;
    const storageRef = ref(storage, `/files/images/dpThumb.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed", () => {
      getDownloadURL(coverRef).then((res) => {
        setCoverUrl(res);
      });
    });
  };

  const formHandler = async (e) => {
    try {
      e.preventDefault();
      let file = e.target[0].files[0];
      const imageThumb = await resizeFileThumb(file);
      const image = await resizeFile(file);

      setProgressVisible("block");

      uploadFileThumb(imageThumb);
      uploadFileCompressed(image);
    } catch (err) {
      console.log(err);
    }
  };

  const passChangeHandler = (e, input) => {
    switch (input) {
      case "pass":
        setPassInput(e.target.value);
        break;
      case "verify":
        setPassVerify(e.target.value);
        break;
      default:
        alert("Error!");
    }
  };
  const updateLoginDb = async () => {
    const loginDoc = doc(db, "adminLogin", "Fudk6j0xse7MUgH6Z0jM");
    const loginInput = {
      username: UsernameInput,
      password: passInput,
    };
    await updateDoc(loginDoc, loginInput);
    alert("User Login Updated");
  };

  const updataeLandingText = async () => {
    const landingDoc = doc(db, "landingtext", landingText[0].id);
    const landingInputUpdate = {
      text: landingInput,
    };
    await updateDoc(landingDoc, landingInputUpdate);
    alert("Landing Page Text Updated");
  };

  const updateLogin = () => {
    passInput === passVerify
      ? updateLoginDb()
      : alert("Password Does Not Match!");
  };

  const renderCoverLoading = () => {
    return coverLoading ? (
      <button
        disabled
        type="button"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
      >
        <svg
          aria-hidden="true"
          role="status"
          className="inline w-4 h-4 mr-3 text-white animate-spin"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="#E5E7EB"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentColor"
          />
        </svg>
        Loading...
      </button>
    ) : (
      <img className="w-full h-full object-cover" src={coverUrl} alt="" />
    );
  };

  return (
    <React.Fragment>
      <div
        className={
          "bg-white w-full h-[calc(100vh-34px)] p-2 rounded-xl shadow-md flex flex-col overflow-auto"
        }
      >
        <div>
          <h1 className="font-bold text-2xl">
            Landing Page and Basic Settings
          </h1>
          <div className="border-b-2 border-b-slate-700 pb-8 flex flex-col lg:flex-row justify-center items-center lg:gap-16">
            <div className="rounded-xl h-[200px] w-full overflow-hidden mb-4 flex justify-center item lg:h-[350px] lg:w-[650px]">
              {renderCoverLoading()}
            </div>
            <div>
              <form className="flex flex-col gap-2" onSubmit={formHandler}>
                <input
                  className="rounded-xl bg-stone-200 w-full"
                  type="file"
                  name=""
                  id=""
                  accept="image/jpeg"
                />
                <button
                  className="bg-sky-300 h-10 rounded-xl font-bold"
                  type="submit"
                >
                  UPLOAD
                </button>
              </form>
              <div
                className={`mt-4 h-1 w-full bg-neutral-200 dark:bg-neutral-600`}
              >
                <div
                  className={`h-1 bg-sky-600 w-[${progressbarValue}%]`}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4 flex flex-col lg:flex-row justify-center items-center gap-4 lg:gap-16">
          <div className="flex flex-col gap-4 w-full lg:w-[500px]">
            <h3 className="font-bold text-base">Update Landing Page Text:</h3>
            <textarea
              className="w-full resize-none"
              rows="4"
              value={landingInput}
              onChange={(e) => {
                setLandinginput(e.target.value);
              }}
            ></textarea>
            <button
              className="bg-sky-300 rounded-xl h-12"
              onClick={updataeLandingText}
            >
              Update Landing Page Text
            </button>
          </div>
          <div className="flex flex-col">
            <h3 className="font-bold text-base">Update Login Details:</h3>
            <label htmlFor="">Username:</label>
            <input
              type="text"
              value={UsernameInput}
              onChange={(e) => {
                setUsernameInput(e.target.value);
              }}
            />
            <label htmlFor="">Password:</label>
            <input
              type="password"
              value={passInput}
              onChange={(e) => {
                passChangeHandler(e, "pass");
              }}
            />
            <label htmlFor="">Confirm Password:</label>
            <input
              type="password"
              value={passVerify}
              onChange={(e) => {
                passChangeHandler(e, "verify");
              }}
            />
            <button
              className="bg-sky-300 rounded-xl h-12 mt-4"
              onClick={updateLogin}
            >
              Update User Login
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Landing;

//import { DomainVerification } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import AddNews from "./newsComps/AddNews";
import editIcon from "./svg/edit.svg";
import deleteIcon from "./svg/delete.svg";
import EditNews from "./newsComps/EditNews";
import AddAnnounce from "./newsComps/AddAnnounce";
import EditAnnounce from "./newsComps/EditAnnounce";
import DelAlert from "./newsComps/DelAlert";

function NewsAdmin() {
  const newsRef = collection(db, "news");
  const announcementsRef = collection(db, "announcements");
  const [news, setNews] = useState([]);
  const [announce, setAnnounce] = useState([]);
  const [activeNewsForm, setActiveNewsForm] = useState(false);
  const [activeNewsEditForm, setActiveNewsEditForm] = useState(false);
  const [activeNews, setActiveNews] = useState({
    id: "",
    title: "",
    date: "",
    lead: "",
    body: "",
  });
  const [activeNewAnnounce, setActiveNewAnnounce] = useState(false);
  const [activeAnnounceEdit, setActiveAnnounceEdit] = useState(false);
  const [activeAnnouncement, setActiveAnnouncement] = useState({
    id: "",
    title: "",
    date: "",
  });
  const [confirmDelNews, setConfirmDelNews] = useState(false);
  const [confirmDelAnnounce, setConfirmDelAnnounce] = useState(false);
  const [deleteNewsId, setDeleteNewsId] = useState("");
  const [deleteAnnounceId, setDeleteAnnounceId] = useState("");

  const getNews = async () => {
    const data = await getDocs(newsRef);
    setNews(
      data.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => {
          let da = new Date(a.date);
          let db = new Date(b.date);
          return db - da;
        })
    );
  };
  const getAnnouncements = async () => {
    const data = await getDocs(announcementsRef);
    setAnnounce(
      data.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => {
          let da = new Date(a.date);
          let db = new Date(b.date);
          return db - da;
        })
    );
  };
  useEffect(() => {
    getNews();
    getAnnouncements();
  }, []);

  const blurActive = () => {
    if (
      activeNewsForm ||
      activeNewsEditForm ||
      activeNewAnnounce ||
      activeAnnounceEdit
    ) {
      return "blur";
    } else {
      return "";
    }
  };

  const renderNewsForm = () => {
    if (activeNewsForm) {
      return "block";
    } else {
      return "hidden";
    }
  };

  const closeAddNewsForm = () => {
    setActiveNewsForm(false);
  };

  const closeNewsEditForm = () => {
    setActiveNewsEditForm(false);
  };

  const deleteNews = async (id) => {
    setConfirmDelNews(false);
    const newsDoc = doc(db, "news", id);
    await deleteDoc(newsDoc);
    alert("News Deleted!");
    await getNews();
  };

  const deleteAnnounce = async (id) => {
    setConfirmDelAnnounce(false);
    const newsDoc = doc(db, "announcements", id);
    await deleteDoc(newsDoc);
    alert("Announcement Deleted!");
    await getAnnouncements();
  };

  const editClick = (ind) => {
    setActiveNews({
      id: news[ind].id,
      title: news[ind].title,
      date: news[ind].date,
      lead: news[ind].lead,
      body: news[ind].newsBody,
    });
    setActiveNewsEditForm(true);
  };

  const editAnnounceClick = (ind) => {
    setActiveAnnouncement({
      id: announce[ind].id,
      title: announce[ind].title,
      date: announce[ind].date,
    });
    setActiveAnnounceEdit(true);
  };

  const renderEditNewsForm = () => {
    if (activeNewsEditForm) {
      return (
        <EditNews
          closeForm={closeNewsEditForm}
          refreshNews={getNews}
          activeNews={activeNews}
        />
      );
    }
  };

  const renderNewAnnounce = () => {
    if (activeNewAnnounce) {
      return (
        <AddAnnounce
          closeForm={() => setActiveNewAnnounce(false)}
          refreshNews={getAnnouncements}
        />
      );
    }
  };

  const renderEditAnnounce = () => {
    if (activeAnnounceEdit) {
      return (
        <EditAnnounce
          closeForm={() => setActiveAnnounceEdit(false)}
          refreshNews={getAnnouncements}
          activeNews={activeAnnouncement}
        />
      );
    }
  };

  const renderConfirmDelNews = () => {
    if (confirmDelNews) {
      return (
        <div className="absolute h-screen w-screen bg-neutral-300 top-0 left-0 bg-opacity-50">
          <DelAlert
            title="News"
            cancel={() => {
              setConfirmDelNews(false);
            }}
            confirm={() => {
              deleteNews(deleteNewsId);
            }}
          />
        </div>
      );
    }
  };
  const renderConfirmDelAnnounce = () => {
    if (confirmDelAnnounce) {
      return (
        <div className="absolute h-screen w-screen bg-neutral-300 top-0 left-0 bg-opacity-50">
          <DelAlert
            title="Announcement"
            cancel={() => {
              setConfirmDelAnnounce(false);
            }}
            confirm={() => {
              deleteAnnounce(deleteAnnounceId);
            }}
          />
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div
        className={`bg-white w-full h-[calc(100vh-34px)] p-2 rounded-xl shadow-md flex flex-col overflow-auto ${blurActive()}`}
      >
        <h1 className="font-bold text-2xl">News and Announcements</h1>
        <div className="flex flex-col gap-2 mt-4 mx-4 md:flex-row md:gap-8">
          <div className="flex flex-col gap-2 md:w-[100%]">
            <h3 className="text-base font-bold">News</h3>
            <div
              className="bg-slate-200 h-96 rounded p-2 overflow-auto 
          md:h-[calc(100vh-180px)]"
            >
              {news.map((val, key) => {
                return (
                  <div
                    key={key}
                    className="border-b-[1px] border-slate-900 py-2 flex flex-row items-center"
                  >
                    <div className="flex-1">
                      <h5 className="text-base">{val.title}</h5>
                      <p className="text-md text-sky-600">{val.date}</p>
                    </div>
                    <div className="flex flex-row gap-2">
                      <img
                        className="cursor-pointer"
                        src={editIcon}
                        onClick={() => editClick(key)}
                      />
                      <img
                        className="cursor-pointer"
                        src={deleteIcon}
                        onClick={() => {
                          setConfirmDelNews(true);
                          setDeleteNewsId(val.id);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row justify-evenly md:justify-end md:gap-4">
              <div
                className="w-24 bg-sky-600 h-8 text-white rounded-xl flex justify-center items-center font-bold cursor-pointer"
                onClick={() => setActiveNewsForm(true)}
              >
                Add News
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 md:w-[70%]">
            <h3 className="text-base font-bold">Announcements</h3>
            <div className="bg-slate-200 h-64 rounded p-2 overflow-auto md:h-[calc(100vh-180px)]">
              {announce.map((val, key) => {
                return (
                  <div
                    key={key}
                    className="border-b-[1px] border-slate-900 py-2 flex flex-row items-center"
                  >
                    <div className="flex-1">
                      <h5 className="text-base">{val.title}</h5>
                      <p className="text-md text-sky-600">{val.date}</p>
                    </div>
                    <div className="flex flex-row gap-2">
                      <img
                        className="cursor-pointer"
                        src={editIcon}
                        alt=""
                        onClick={() => editAnnounceClick(key)}
                      />
                      <img
                        className="cursor-pointer"
                        src={deleteIcon}
                        alt=""
                        onClick={() => {
                          setConfirmDelAnnounce(true);
                          setDeleteAnnounceId(val.id);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row justify-evenly md:justify-end md:gap-4">
              <div
                className="w-48 bg-sky-600 h-8 text-white rounded-xl flex justify-center items-center font-bold text-center"
                onClick={() => {
                  setActiveNewAnnounce(true);
                }}
              >
                New Announcement
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddNews
        renderNewsForm={renderNewsForm}
        closeForm={closeAddNewsForm}
        refreshNews={getNews}
      />
      {renderEditNewsForm()}
      {renderNewAnnounce()}
      {renderEditAnnounce()}
      {renderConfirmDelNews()}
      {renderConfirmDelAnnounce()}
    </React.Fragment>
  );
}

export default NewsAdmin;

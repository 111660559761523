import React, { useState, useEffect } from "react";

function FeaturePage(props) {
  const data = props.data;
  const features = props.features;

  useEffect(() => {
    document.title = data.title;
  }, []);
  return (
    <div className="w-screen flex items-center justify-center">
      <div className="p-8 w-screen flex flex-col xl:flex-row xl:w-[1200px] xl:gap-10">
        <div className="xl:w-auto xl:flex-1">
          <h1 className="text-2xl font-extrabold xl:text-4xl">{data.title}</h1>
          <p className="text-sky-600">{data.date}</p>
          <div>
            <img src={data.imgurl} alt="" />
          </div>
          <div className="py-4 xl:text-base">
            {data.body.split("\n").map((p, key) => {
              return (
                <p className="mb-2" key={key}>
                  {p}
                </p>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col xl:w-[350px] xl:border-l-2 xl:pl-10 xl:border-sky-600 box-content">
          <h2 className="text-lg text-sky-600 font-extrabold">
            Other Features
          </h2>
          <span className="border-t-2 border-sky-600 h-1"></span>
          <div className="flex flex-col gap-2 mt-2 xl:gap-4">
            {features.map((val, key) => {
              return (
                <a
                  className="underline underline-offset-4 xl:no-underline xl:hover:text-black xl:text-sky-800 xl:hover:font-bold"
                  href={`../${val.url}`}
                  key={key}
                >
                  {val.title}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturePage;

import { useState } from "react";
import logo from "../res/logo.png";
import { Link } from "react-router-dom";

function NavBar(props) {
  const homeClass = props.homeClass;
  const progClass = props.progClass;
  const aboutClass = props.aboutClass;
  const journalsClass = props.journalsClass;

  const [isOpen, setIsOpen] = useState(false);

  const renderNavBarClass = () => {
    return isOpen ? "" : "hidden";
  };

  const collapseSwitch = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };
  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900 w-[100vw] fixed top-0 box drop-shadow-md z-10">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/">
          <img src={logo} className="h-14 mr-1" alt="PEACI Logo" />
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={collapseSwitch}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          className={`${renderNavBarClass()} w-full md:block md:w-auto transition-all transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) transition-duration: 150ms`}
          id="navbar-default"
        >
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <a href="../" className={homeClass}>
                Home
              </a>
            </li>
            <li>
              <a href="../programs" className={progClass}>
                Programs
              </a>
            </li>
            <li>
              <a href="../journal" className={journalsClass}>
                Journal
              </a>
            </li>
            <li>
              <a href="../about" className={aboutClass}>
                About PEACI
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;

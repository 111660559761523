import React, { useState, useEffect, useRef } from "react";
import cover from "../res/peaci-ched-anniv.jpg";
import History from "../pages/History";
import historyMd from "../history.md";
import classNames from "classnames";

function About(props) {
  const [historyCollapse, setHistoryCollapse] = useState(false);
  const historyRef = useRef(null);

  useEffect(() => {
    document.title = "About PEACI";
    props.setActivePage();
    // eslint-disable-next-line
  }, []);

  const renderShowMore = () => {
    return !historyCollapse ? "Show More" : "Show Less";
  };

  const triggerHistoryCollapse = () => {
    if (historyCollapse)
      historyRef.current?.scrollIntoView({ behavior: "smooth" });

    historyCollapse ? setHistoryCollapse(false) : setHistoryCollapse(true);
  };

  return (
    <div id="top" className="about">
      <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
        ABOUT PEACI
      </h1>
      <div className="separator small"></div>

      <div className="xl:w-[1200px] pt-5 pb-10 w-[100vw] px-10">
        <p className="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400 tracking-tigt text-center">
          {" "}
          The Philippine Engineering and Agro-Industrial College, Inc. is
          committed to the total development of honest, trustworthy and
          God-fearing servants to the search of truth, virtue, justice, equality
          and academic excellence of highest degree.
        </p>
      </div>
      <div className="bg-stone-800 w-[100vw] py-10">
        <ul className="flex text-stone-300 items-center justify-center gap-4 md:gap-16 md:text-2xl text-xl font-light flex-col md:flex-row ">
          <li>
            <a
              className="transition duration-300 hover:transition hover:duration-300 hover:text-sky-600"
              href="#history"
            >
              Brief History
            </a>
          </li>
          <li>
            <a
              className="transition duration-300 hover:transition hover:duration-300 hover:text-sky-600"
              href="#mission"
            >
              Mission
            </a>
          </li>
          <li>
            <a
              className="transition duration-300 hover:transition hover:duration-300 hover:text-sky-600"
              href="#vision"
            >
              Vision
            </a>
          </li>
          <li>
            <a
              className="transition duration-300 hover:transition hover:duration-300 hover:text-sky-600"
              href="#objectives"
            >
              Objectives
            </a>
          </li>
          <li>
            <a
              className="transition duration-300 hover:transition hover:duration-300 hover:text-sky-600"
              href="#goals"
            >
              Goals
            </a>
          </li>
        </ul>
      </div>
      <div
        id="history"
        className="mt-8 w-[90vw] xl:w-[1200px] flex flex-col justify-start items-center "
      >
        <h2 className="text-4xl font-extrabold dark:text-white text-center mb-4">
          Brief History
        </h2>
        <figure className="w-[80vw] md:max-w-lg text-center" ref={historyRef}>
          <img
            className="h-auto max-w-full rounded-lg"
            src={cover}
            alt="CHED Anniversary"
          />
          <figcaption className="mt-2 text-sm text-center text-gray-500 dark:text-gray-400">
            PEACI @ CHED Anniversary 2016
          </figcaption>
        </figure>
        <div
          className={`transition-all duration-500 overflow-hidden ${
            historyCollapse
              ? "h-auto"
              : "h-[220px] border-b-[1px] border-b-sky-600"
          }`}
        >
          <History filePath={historyMd} />
        </div>
        <button
          type="button"
          className="py-2.5 px-5 mr-2 my-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          onClick={triggerHistoryCollapse}
        >
          {renderShowMore()}
        </button>
        <a
          href="#top"
          className="text-right w-[100%] text-sky-600 hover:underline hover:underline-offset-4"
        >
          Back to Top
        </a>
      </div>
      <div
        id="mission"
        className="bg-stone-800 w-[100vw] flex flex-col justify-center items-center py-10 mt-4"
      >
        <div className="w-[90vw] xl:w-[1200px] flex flex-col justify-center items-center">
          <h2 className="text-4xl font-extrabold text-white text-center">
            Mission
          </h2>

          <blockquote className="text-2xl italic font-light text-stone-300 dark:text-white">
            <svg
              aria-hidden="true"
              className="w-10 h-10 text-gray-400 dark:text-gray-600"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <p>
              Dedicated to introduce reforms to attain peace, prosperity,
              equality and sustainable development in the ARMM, the PEACI will
              lead the standards of excellence in science and technology, arts
              and sports; serve as the catalyst for the economic, cultural,
              socio-political, and agro-industrial development of the Muslims,
              Lumads and other indigenous groups, thereby transforming them into
              good productive members of the society possessing the highest
              degree of intelligence, honesty, morality, and excellence; and
              encourage them for the alleviation of poverty and the promotion of
              employment, and improved quality of life for all.
            </p>
          </blockquote>
          <a
            href="#top"
            className="text-right w-[100%] text-sky-600 hover:underline hover:underline-offset-4 mt-6"
          >
            Back to Top
          </a>
        </div>
      </div>
      <div
        id="vision"
        className="bg-stone-600 w-[100vw] flex flex-col justify-center items-center py-10"
      >
        <div className="w-[90vw] xl:w-[1200px] flex flex-col justify-center items-center">
          <h2 className="text-4xl font-extrabold text-white text-center">
            Vision
          </h2>

          <blockquote className="text-2xl italic font-light text-stone-300 dark:text-white">
            <svg
              aria-hidden="true"
              className="w-10 h-10 text-gray-400 dark:text-gray-600"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <p>
              The Philippine Engineering and Agro-Industrial College, Inc.
              aspires to be the modern World-Class institution of highest
              academic excellence in the Autonomous Region in Muslim Mindanao
              (ARMM).
            </p>
          </blockquote>
          <a
            href="#top"
            className="text-right w-[100%] text-sky-600 hover:underline hover:underline-offset-4 mt-6"
          >
            Back to Top
          </a>
        </div>
      </div>
      <div
        id="objectives"
        className="bg-stone-400 w-[100vw] flex flex-col justify-center items-center py-10"
      >
        <div className="w-[90vw] xl:w-[1000px] flex flex-col justify-center items-center">
          <h2 className="text-4xl font-extrabold text-black text-center">
            Objectives
          </h2>
          <ul className=" text-black list-disc list-inside dark:text-gray-400 mt-8 text-xl font-light">
            <li className="mb-4">
              To provide competent, high moral, honest, trustworthy,
              service-oriented, intelligent, God-fearing and world-class
              graduates who are skillful and globally competitive in their
              special field of specialization.
            </li>
            <li className="mb-4">
              To produce the necessary workforce for the socio-economic,
              political and agro-industrial development of Mindanao,
              particularly the ARMM.
            </li>
            <li className="mb-4">
              To produce globally competitive technicians, educators,
              researchers and inventors who will serve as instruments for the
              attainment of mutual understanding, unity and lasting peace and
              sustainable development of the ARMM.
            </li>
          </ul>
          <a
            href="#top"
            className="text-right w-[100%] text-sky-800 hover:underline hover:underline-offset-4 mt-6"
          >
            Back to Top
          </a>
        </div>
      </div>

      <div
        id="goals"
        className=" w-[100vw] flex flex-col justify-center items-center py-10"
      >
        <div className="w-[90vw] xl:w-[1000px] flex flex-col justify-center items-center">
          <h2 className="text-4xl font-extrabold text-black text-center">
            Goals
          </h2>
          <ul className=" text-stone-600 list-disc list-inside dark:text-gray-400 mt-8 text-xl font-light">
            <li className="mb-4">
              To provide and maintain a progressive educational institution of
              highest academic standards in strategic areas in Mindanao
              specially conflict-affected areas of the Autonomous Region in
              Muslim Mindanao (ARMM), where quality of education is in need.
            </li>
            <li className="mb-4">
              To offer elementary, secondary, tertiary, graduate and
              post-graduate courses as well as vocational, scientific and
              artistic courses in accordance with most updated, relevant,
              responsive and world-class curriculum at low and reasonable cost.
              It shall give emphasis on cultural development and preservation
              and scientific and Islamic researches necessary for the
              socio-economic, political, and religious development of ARMM.
            </li>
            <li className="mb-4">
              To provide free, available and accessible quality education for
              the poorest of the poor, orphans, under-privileged and
              disadvantaged children. It shall also give more attentive
              recognition, development and utilization of the extra-academically
              gifted.
            </li>
          </ul>
          <a
            href="#top"
            className="text-right w-[100%] text-sky-800 hover:underline hover:underline-offset-4 mt-6"
          >
            Back to Top
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;

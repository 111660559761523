import React, { useState, useEffect } from "react";
import CollegesComp from "./programsComp/CollegesComp";
import { storage, db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";

function Programs(props) {
  const officesRef = collection(db, "offices");
  const programsRef = collection(db, "programs");
  let coverRef = ref(storage, "files/images/dp.jpg");

  const [coverUrl, setCoverUrl] = useState(
    "http://localhost:3000/static/media/logo.bbd43f561d3fc0429b9e.png"
  );
  const [offices, setOffices] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [officesRender, setOfficesRender] = useState([
    {
      idOffice: 0,
      name: "Loading",
      shortName: "",
      logoUrl: "",
      departments: [{ name: "loading" }],
    },
  ]);

  let officesLoad = [];
  let programsLoad = [];

  const getOffices = async () => {
    const data = await getDocs(officesRef);
    officesLoad = data.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }))
      .sort((a, b) => {
        let da = a.idOffice;
        let db = b.idOffice;
        return da - db;
      });
    setOffices(officesLoad);
  };

  const getPrograms = async () => {
    const data = await getDocs(programsRef);
    programsLoad = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setPrograms(programsLoad);
  };

  const arrangeOfficePrograms = () => {
    let officesNew = [];

    officesLoad.forEach((val) => {
      let officeTemp = {
        idOffice: parseInt(val.idOffice),
        name: val.name,
        shortName: val.shortName,
        logoUrl: val.logoUrl,
        departments: [],
      };

      programsLoad.forEach((val2) => {
        if (val2.idOffice == val.idOffice) {
          officeTemp.departments.push(val2);
        }
      });

      officesNew.push(officeTemp);
    });

    setOfficesRender(officesNew);
  };

  useEffect(() => {
    document.title = "PEACI - Programs";
    getOffices();
    getPrograms().then(() => {
      arrangeOfficePrograms();
    });
    props.setActivePage();
    getDownloadURL(coverRef).then((res) => {
      setCoverUrl(res);
    });

    // eslint-disable-next-line
  }, []);
  return (
    <div className="programs">
      <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
        PROGRAMS
      </h1>
      <div className="separator small"></div>
      <div className="w-[100vw] md:w-[max(570px)] h-[285px] bg-black md:rounded-3xl">
        <img
          className="object-cover h-full w-full md:rounded-3xl"
          src={coverUrl}
          alt=""
        />
      </div>
      <div className="md:w-[max(570px)] w-[90vw] text-center text-stone-700 py-5">
        <p className="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400">
          {" "}
          PEACI offers the quality education needed by the students to meet the
          competitive global standards. The institution has 8 undergraduate
          programs which they could choose to suit their individual talents and
          passion.
        </p>
      </div>

      {officesRender.map((val, key) => {
        return <CollegesComp key={key} data={val} />;
      })}
    </div>
  );
}

export default Programs;

import Departments from "./Departments";

function CollegesComp(props) {
  const data = props.data;

  const renderLogo = () => {
    return data.logoUrl ? <img src={data.logoUrl} alt={data.name} /> : "";
  };

  return (
    <div className={`college bg-stone-900 text-stone-300`}>
      <div className="flex w-[100vw] md:w-[1200px] flex-col items-center py-10 px-20">
        <h2 className="text-4xl font-extrabold text-center">{data.name}</h2>
        <div className="separator small"></div>
        <div className="flex justify-center items-center md:items-start gap-10 md:gap-[44px] md:flex-row flex-col">
          <div className="w-[280px] flex justify-center items-center">
            {renderLogo()}
          </div>
          <div className="flex-1 w-[auto]">
            {data.departments.map((val, key) => {
              return <Departments key={key} data={val} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollegesComp;

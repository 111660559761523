import React, { useState, useEffect } from "react";

import Admission from "./programsComp/Admission";

function DepartmentPage(props) {
  const data = props.data;
  const [programs, setPrograms] = useState(data);

  useEffect(() => {
    props.setActivePage();
  }, []);
  return (
    <div className="flex flex-col md:flex-row w-[100vw]">
      <div className="py-4 pb-0 flex-1 w-auto md:mb-0 xl:px-32">
        <div className="px-8 md:text-center">
          <h2 className="text-3xl font-light text-gray-500 dark:text-gray-400 capitalize">
            {programs.bachelor}
          </h2>
          <h1 className="text-4xl font-extrabold dark:text-white">
            {programs.degree}
          </h1>
        </div>

        <div className="text-lg px-8 mt-4 md:text-center text-stone-600 ">
          {programs.longDesc}
        </div>
        <div className="px-8 py-8 mt-4">
          <h3 className="text-3xl text-stone-800 md:text-center border-0 border-t-4 border-sky-900 py-4">
            Career Paths
          </h3>
          <ul className="list-disc ml-4 mt-4 text-lg text-black font-light space-y-2">
            <li>{programs.p1}</li>
            <li>{programs.p2}</li>
            <li>{programs.p3}</li>
            <li>{programs.p4}</li>
            <li>{programs.p5}</li>
            <li>{programs.p6}</li>
            <li>{programs.p7}</li>
            <li>{programs.p8}</li>
            <li>{programs.p9}</li>
            <li>{programs.p10}</li>
          </ul>
        </div>
      </div>
      <Admission />
    </div>
  );
}

export default DepartmentPage;

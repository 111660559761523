import logo from "../../../res/logo.png";

function Publisher() {
  return (
    <section
      id="publisher"
      className="bg-sky-100 flex py-12 justify-center items-center"
    >
      <div className="w-[1000px] flex flex-col">
        <h1 className="text-5xl font-light text-center text-sky-900 mb-4 lg:text-right lg:text-6xl">
          THE PUBLISHER
        </h1>
        <div className="flex flex-col justify-center items-center gap-8 lg:flex-row">
          <img className="w-[85vw] lg:w-[350px]" src={logo} alt="" />
          <div className="px-4 lg:flex-1 lg:w-auto text-center lg:text-left">
            <p className="text-2xl font-bold mb-4 lg:text-3xl text-sky-900">
              The AJORIST is published by the Philippine Engineering and
              Agro-Industrial College, Inc.
            </p>
            <p className="text-base font-light lg:text-xl">
              The Philippine Engineering and Agro-Industrial College, Inc.
              (PEACI) is a private, non-stock, non-profit educational
              institution, founded in 2007 by a group of Meranao engineers.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Publisher;

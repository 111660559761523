import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebaseConfig";

function DepartmentNew(props) {
  const departmentsRef = collection(db, "programs");
  const [bachelor, setBachelor] = useState("");
  const [degree, setDegree] = useState("");
  const [name, setName] = useState("");
  const [idOffice, setIdOffice] = useState(0);
  const [shortDesc, setShordDesc] = useState("");
  const [longDesc, setLongDesc] = useState("");
  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");
  const [p3, setP3] = useState("");
  const [p4, setP4] = useState("");
  const [p5, setP5] = useState("");
  const [p6, setP6] = useState("");
  const [p7, setP7] = useState("");
  const [p8, setP8] = useState("");
  const [p9, setP9] = useState("");
  const [p10, setP10] = useState("");

  const addnewDepartment = async () => {
    await addDoc(
      departmentsRef,
      {
        bachelor: bachelor,
        degree: degree,
        name: name,
        idOffice: parseInt(idOffice),
        shortDesc: shortDesc,
        longDesc: longDesc,
        p1: p1,
        p2: p2,
        p3: p3,
        p4: p4,
        p5: p5,
        p6: p6,
        p7: p7,
        p8: p8,
        p9: p9,
        p10: p10,
        url: name.replace(/ /g, "-").toLowerCase(),
      },
      alert("Department Added!")
    );
    props.close();
  };
  return (
    <div className="absolute w-full h-full m-0 p-0 border-0 top-0 left-0">
      <div className="fixed w-screen h-screen bg-slate-800 opacity-20"></div>
      <div className="fixed w-screen h-screen p-8 flex">
        <div className="relative w-full h-full bg-white rounded-2xl p-4 overflow-y-hidden">
          <div
            className="top-2 right-2 bg-rose-700 rounded-full h-6 w-6 absolute flex items-center justify-center font-bold text-white cursor-pointer hover:bg-rose-500"
            onClick={props.close}
          >
            x
          </div>
          <div className="h-full flex flex-col">
            <h2 className="text-xl font-bold mb-4 h-7">New Department</h2>
            <div className="h-auto flex-1 overflow-x-hidden overflow-y-scroll flex flex-col divide-y divide-sky-600">
              <div className="flex flex-row justify-between items-center py-3">
                <label className="w-20" htmlFor="">
                  Degree:
                </label>
                <input
                  className="rounded-full px-4 w-44  xl:flex-1"
                  type="text"
                  placeholder="Bachelor of Science"
                  value={bachelor}
                  onChange={(e) => {
                    setBachelor(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-row justify-between items-center py-3">
                <label className="w-20" htmlFor="">
                  Course:
                </label>
                <input
                  className="rounded-full px-4 w-44  xl:flex-1"
                  type="text"
                  placeholder="Civil Engineering"
                  value={degree}
                  onChange={(e) => {
                    setDegree(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-row justify-between items-center py-3">
                <label className="w-20" htmlFor="">
                  Full Course Name:
                </label>
                <input
                  className="rounded-full px-4 w-44  xl:flex-1"
                  type="text"
                  placeholder="BS Civil Engineering"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-row justify-between items-center py-3">
                <label className="w-20" htmlFor="">
                  Office:
                </label>
                <select
                  className="rounded-full px-4 w-44  xl:flex-1"
                  value={idOffice}
                  onChange={(e) => {
                    setIdOffice(e.target.value);
                  }}
                >
                  <option value="0" disabled hidden>
                    Select Office
                  </option>
                  {props.offices.map((val, key) => {
                    return (
                      <option key={key} value={val.idOffice}>
                        {val.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="flex flex-row justify-between items-center py-3">
                <label className="w-20" htmlFor="">
                  Short Description:
                </label>
                <textarea
                  className="rounded-full px-4 w-44  xl:flex-1"
                  type="text"
                  placeholder="This will be displayed on the programs pages"
                  value={shortDesc}
                  onChange={(e) => {
                    setShordDesc(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-row justify-between items-center py-3">
                <label className="w-20" htmlFor="">
                  Long Description:
                </label>
                <textarea
                  className="rounded-full px-4 w-44  xl:flex-1"
                  type="text"
                  placeholder="This will be displayed on the page of the respective program"
                  value={longDesc}
                  onChange={(e) => {
                    setLongDesc(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-row justify-between items-center py-3">
                <label className="w-20" htmlFor="">
                  Career Paths:
                </label>
                <div className="w-44 flex flex-col gap-2 xl:flex-1">
                  <input
                    className="rounded-full w-full"
                    type="text"
                    placeholder="Path 1"
                    value={p1}
                    onChange={(e) => {
                      setP1(e.target.value);
                    }}
                  />
                  <input
                    className="rounded-full w-full"
                    type="text"
                    placeholder="Path 2"
                    value={p2}
                    onChange={(e) => {
                      setP2(e.target.value);
                    }}
                  />
                  <input
                    className="rounded-full w-full"
                    type="text"
                    placeholder="Path 3"
                    value={p3}
                    onChange={(e) => {
                      setP3(e.target.value);
                    }}
                  />
                  <input
                    className="rounded-full w-full"
                    type="text"
                    placeholder="Path 4"
                    value={p4}
                    onChange={(e) => {
                      setP4(e.target.value);
                    }}
                  />
                  <input
                    className="rounded-full w-full"
                    type="text"
                    placeholder="Path 5"
                    value={p5}
                    onChange={(e) => {
                      setP5(e.target.value);
                    }}
                  />
                  <input
                    className="rounded-full w-full"
                    type="text"
                    placeholder="Path 6"
                    value={p6}
                    onChange={(e) => {
                      setP6(e.target.value);
                    }}
                  />
                  <input
                    className="rounded-full w-full"
                    type="text"
                    placeholder="Path 7"
                    value={p7}
                    onChange={(e) => {
                      setP7(e.target.value);
                    }}
                  />
                  <input
                    className="rounded-full w-full"
                    type="text"
                    placeholder="Path 8"
                    value={p8}
                    onChange={(e) => {
                      setP8(e.target.value);
                    }}
                  />
                  <input
                    className="rounded-full w-full"
                    type="text"
                    placeholder="Path 9"
                    value={p9}
                    onChange={(e) => {
                      setP9(e.target.value);
                    }}
                  />
                  <input
                    className="rounded-full w-full"
                    type="text"
                    placeholder="Path 10"
                    value={p10}
                    onChange={(e) => {
                      setP10(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="h-7">
              <button
                className="bg-sky-600 text-white font-bold py-2 rounded-full"
                onClick={addnewDepartment}
              >
                Add New Department
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepartmentNew;

import React, { useState, useEffect } from "react";
import logo40 from "../res/Logo/logo40px.png";
import NewsAdmin from "./NewsAdmin";
import CollegesAdmin from "./CollegesAdmin";
import Landing from "./Landing";
import FeaturedAdmin from "./FeaturedAdmin";

function AdminHome(props) {
  const activeTabClass =
    "cursor-pointer t group relative flex justify-center rounded bg-blue-50 px-2 py-1.5 text-blue-700";
  const inactiveTabClass =
    "cursor-pointer group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700";

  const [activePage, setActivePage] = useState(0);
  const [landingClass, setLandingClass] = useState(activeTabClass);
  const [newsClass, setNewsClass] = useState(inactiveTabClass);
  const [departmentsClass, setDepartmentClass] = useState(inactiveTabClass);
  const [featuresClass, setFeaturesClass] = useState(inactiveTabClass);

  useEffect(() => {
    document.title = "PEACI - WebAdmin";
  }, []);

  useEffect(() => {
    switch (activePage) {
      case 0:
        setLandingClass(activeTabClass);
        setNewsClass(inactiveTabClass);
        setDepartmentClass(inactiveTabClass);
        setFeaturesClass(inactiveTabClass);
        break;
      case 1:
        setLandingClass(inactiveTabClass);
        setNewsClass(activeTabClass);
        setDepartmentClass(inactiveTabClass);
        setFeaturesClass(inactiveTabClass);
        break;
      case 2:
        setLandingClass(inactiveTabClass);
        setNewsClass(inactiveTabClass);
        setDepartmentClass(activeTabClass);
        setFeaturesClass(inactiveTabClass);
        break;
      case 3:
        setLandingClass(inactiveTabClass);
        setNewsClass(inactiveTabClass);
        setDepartmentClass(inactiveTabClass);
        setFeaturesClass(activeTabClass);
        break;
      default:
        setLandingClass(activeTabClass);
        setNewsClass(inactiveTabClass);
        setDepartmentClass(inactiveTabClass);
        setFeaturesClass(inactiveTabClass);
    }
  }, [activePage]);

  const renderPage = () => {
    switch (activePage) {
      case 0:
        return <Landing />;
      case 1:
        return <NewsAdmin />;
      case 2:
        return <CollegesAdmin />;
      case 3:
        return <FeaturedAdmin />;
      default:
        return <NewsAdmin />;
    }
  };

  const renderActivePageClassName = (page) => {
    switch (page) {
      case 0:
        setLandingClass(activeTabClass);
        setNewsClass(inactiveTabClass);
        setDepartmentClass(inactiveTabClass);
        break;
      case 1:
        setLandingClass(inactiveTabClass);
        setNewsClass(activeTabClass);
        setDepartmentClass(inactiveTabClass);
        break;
      case 2:
        setLandingClass(inactiveTabClass);
        setNewsClass(inactiveTabClass);
        setDepartmentClass(activeTabClass);
        break;
      default:
        setLandingClass(activeTabClass);
        setNewsClass(inactiveTabClass);
        setDepartmentClass(inactiveTabClass);
    }
  };

  return (
    <div className="bg-stone-200 flex flex-row">
      <div className="flex h-screen w-16 flex-col justify-between border-e bg-white">
        <div>
          <div className="inline-flex h-16 w-16 items-center justify-center">
            <span className="grid h-10 w-10 place-content-center rounded-lg bg-gray-100 text-xs text-gray-600">
              <img className="h-[90%]" src={logo40} alt="P" />
            </span>
          </div>

          <div className="border-t border-gray-100">
            <nav aria-label="Main Nav" className="flex flex-col p-2">
              <div className="py-4">
                <button
                  id="0"
                  className={landingClass}
                  onClick={() => {
                    setActivePage(0);
                    //renderActivePageClassName(0);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 opacity-75"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>

                  <span className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 text-center">
                    Landing
                  </span>
                </button>
              </div>

              <ul className="space-y-1 border-t border-gray-100 pt-4">
                <li>
                  <button
                    className={newsClass}
                    onClick={() => {
                      setActivePage(1);
                      //renderActivePageClassName(1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 opacity-75"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M20,11H4V8H20M20,15H13V13H20M20,19H13V17H20M11,19H4V13H11M20.33,4.67L18.67,3L17,4.67L15.33,3L13.67,4.67L12,3L10.33,4.67L8.67,3L7,4.67L5.33,3L3.67,4.67L2,3V19A2,2 0 0,0 4,21H20A2,2 0 0,0 22,19V3L20.33,4.67Z" />
                    </svg>

                    <span className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100">
                      News
                    </span>
                  </button>
                </li>
                <li>
                  <button
                    className={departmentsClass}
                    onClick={() => {
                      setActivePage(2);
                      //renderActivePageClassName(2);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 opacity-75"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>

                    <span className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100">
                      Colleges
                    </span>
                  </button>
                </li>

                <li>
                  <button
                    className={featuresClass}
                    onClick={() => {
                      setActivePage(3);
                      //renderActivePageClassName(0);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 opacity-75"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M20 3H4C2.89 3 2 3.89 2 5V19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V5C22 3.89 21.11 3 20 3M5 7H10V13H5V7M19 17H5V15H19V17M19 13H12V11H19V13M19 9H12V7H19V9Z"
                      />
                    </svg>

                    <span className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100">
                      Featured News
                    </span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2">
          <button
            href="../webadmin"
            className="group relative flex w-full justify-center rounded-lg px-2 py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 opacity-75"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>

            <span className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100">
              Logout
            </span>
          </button>
        </div>
      </div>
      <div className="p-4 w-[100%]">{renderPage()}</div>
    </div>
  );
}

export default AdminHome;
